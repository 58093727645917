///
/// Checkout form items
///

@import '../../../../estee_base/scss/sections/checkout/opc_update/_opc_update__placeholders';

$space-form-element: 5%;
$max-form-element: 70%;
$half-form-element: ($max-form-element / 2) + $space-form-element;
$full-form-element: ($half-form-element * 2) + $space-form-element;

.checkout {
  input[type='text'],
  input[type='password'],
  input[type='textfield'],
  textarea {
    width: 100%;
    color: $color-navy;
  }
  label {
    @include text--short--plain;
    @include inline-block;
    margin-bottom: 5px; // requested
    &[data-required]:before {
      content: '';
    }
  }
  .form-item {
    position: relative;
  }
  .form-container {
    @include pie-clearfix;
  }
  .form-container-full {
    @include pie-clearfix;
    width: 100%;
  }
  .first-name {
    @extend %half-checkout-item-left;
  }
  .last-name {
    @extend %half-checkout-item-right;
    padding-left: 0px;
  }
  .city {
    @extend %half-checkout-item-left;
  }
  .state {
    @extend %half-checkout-item-right;
  }
  .postal-code {
    @extend %half-checkout-item-left;
  }
  .country-id--bottom {
    @extend %half-checkout-item-right;
  }
  .phone-1 {
    @extend %half-checkout-item-left;
    position: relative;
  }
  .phone-2 {
    @extend %half-checkout-item-right;
    position: relative;
  }
  .sms-mobile-phone {
    position: relative;
    @extend %half-checkout-item-left;
  }
  .sms-mobile-carrier {
    @extend %half-checkout-item-right;
  }
  .sms-terms-and-conditions {
    a {
      @include link;
    }
  }
  .title,
  .address1,
  .address2,
  .phone-required,
  .country-id--top {
    clear: left;
  }
  .checkbox {
    label {
      text-transform: none;
    }
  }
  .block.right {
    position: absolute;
    right: 0;
  }

  %form-element-full {
    float: none;
    clear: both;
    width: 100%;
    overflow: hidden;
    position: relative;
    @media #{$large-up} {
      width: $full-form-element;
    }
  }

  %form-element-half {
    @extend %form-element-full;
    @media #{$large-up} {
      float: left;
      clear: none;
      width: $half-form-element;
      margin-right: $space-form-element;
      padding-right: 0;
    }
  }

  %form-element-row {
    @extend %form-element-half;
    float: none;
  }
  .column.left {
    #checkout_shipping_panel,
    .sms-promo-wrapper,
    .new-address,
    .select-address {
      .form-item,
      .form_element {
        @extend %form-element-half;
        label {
          display: block;
        }
        select,
        a.selectBox,
        a.selectbox {
          width: 100%;
        }
        &.qas-submit-container,
        &.manual-address,
        &.delivery-instructions,
        &.giftwrap,
        &.card-message,
        &.checkbox,
        &.select-menu {
          @extend %form-element-full;
        }
      }
      .form-item-full {
        @extend %form-element-full;
        label {
          display: block;
        }
        select,
        a.selectBox,
        a.selectbox {
          width: 100%;
        }
      }
      div.form-item.address-to-use {
        @extend %form-element-full;
        margin-bottom: 0;
      }
    }
    .select-address div.address-to-use {
      @extend %form-element-full;
    }
    #qas {
      clear: both;
      .btn {
        @include button--dark;
        @extend %checkout-button;
      }
    }
    #manual-address-fields {
      clear: both;
    }
    .form-element-prefix {
      input {
        width: 48%;
      }
    }
  }
}
