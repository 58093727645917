.ie-8 {
  .wishlist-page {
    .favorite__link-delete {
      text-indent: 5px;
    }
  }
  &#shipping {
    #shipping-page-info-panel {
      input[type='checkbox'] {
        margin-top: 5px;
      }
      input[type='radio'] {
        margin-top: 5px;
      }
      #form-opt-terms {
        input[type='checkbox'] {
          width: auto;
          margin-top: 5px;
        }
      }
    }
    #form-opt-terms {
      input[type='checkbox'] {
        width: auto;
        margin-top: 5px;
      }
    }
  }
  &#shipping_billing {
    .cboxIE {
      #cboxContent {
        float: none !important;
        #cboxClose {
          height: 30px;
          width: 30px;
        }
        #colorbox-default-shipping-checkbox {
          margin-top: 5px;
        }
      }
    }
    #billing__section {
      .main-button-wrapper {
        .address-book__add-address-button {
          float: left !important;
        }
      }
    }
  }
  .registration-page {
    .registration-page__birthday {
      .sex_container {
        span {
          input[type='radio'] {
            padding-top: 5px;
          }
        }
      }
    }
    .email-promotions__container {
      input[type='checkbox'] {
        padding-top: 5px;
      }
    }
  }
  .page-navigation {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .page-navigation__menu-toggle {
    left: auto;
    right: 0;
    top: 75px;
  }
  .page-utilities__cart-count {
    min-width: 20px;
  }
}

.hero-block__boutique-link {
  #{$ie8} & {
    background-repeat: no-repeat;
    background-image: url('/media/images/global/sprites-s38a9885fce.png');
  }
}
