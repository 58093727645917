.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

a.selectBox,
a.selectbox {
  text-transform: none;
}

input[type='tel'] {
  color: #000000;
}

input[type='radio'] ~ label,
input[type='radio'] ~ .label {
  margin-left: 1px;
}

#cboxLoadedContent {
  margin: 35px 0 15px 0;
  .sign-in-component {
    overflow-y: auto;
    overflow-x: hidden;
    height: 475px;
  }
}

.colorbox__wishlist-confirm #cboxLoadedContent {
  margin: 0;
  height: auto !important;
}

.ui-widget-content {
  height: 12em;
  overflow-y: auto;
  width: 38em;
}

.form_element {
  margin-top: 0.4em;
  margin-bottom: 0.8em;
}

.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -22px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-branding__logo {
  float: right;
}

.brand-renutriv,
#beauty_feed,
.is-alternate {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3-white.png');
      }
    }
  }
}

.page-sticky-footer {
  border-bottom: 1px solid #1e2341;
}
/* --mpp start-- */
.product_brief__header.product_brief__sub-line {
  font-size: 16px;
  margin-top: 5px;
}
/* --mpp end-- */
@media print {
  .page-footer,
  .page-header {
    display: none;
  }
  .wishlist-page {
    .account-breadcrumb-wrapper {
      .account-breadcrumb {
        display: none;
      }
    }
  }
  .sidebar-page__content {
    padding-top: 0px;
  }
}

.product_brief__buttons-container {
  .product_brief__sub-panel {
    .product_brief__price-size-select {
      width: auto;
      letter-spacing: 0.1em;
      .selectBox-label {
        padding: 0 0 0 5px;
      }
    }
    .product_brief__quantity {
      width: 50px;
    }
  }
}

#order_arrival_info {
  display: none;
}

.spp-product__benefits,
.spp-product__reviews {
  height: auto !important;
}

.email_popover {
  .email_popover__social_icons {
    display: none;
  }
}

.product_brief__panel .tooltip {
  text-indent: -9999px;
  background: url(/media/images/global/text_img_select_shade01.png);
  background-repeat: no-repeat;
  background-position: 0 0px;
}

.mpp .mpp__header {
  padding-bottom: 5px;
}

.brand-renutriv {
  .spp-product__details {
    .spp-product__details-attribute__label {
      font-weight: bold;
    }
  }
}

.page-footer {
  .page-sticky-footer {
    .page-sticky-footer__left {
      div:first-child {
        border: none;
      }
    }
  }
  .responsive-footer {
    .content .css {
      clear: both;
      float: left;
    }
  }
}

.colorbox--social-share {
  .ee-quote-share {
    &.social-share ul {
      margin: 5px 0 0 0;
    }
  }
  .social-share__link.facebook {
    overflow: inherit;
  }
}

.account-body {
  .account-order .order-status-table {
    .order-status-table__row--header {
      display: table-row;
    }
  }
}

.section-store-locator {
  &.device-pc {
    .store-locator {
      .local-search__search-container {
        width: 340px;
        .local-search-form {
          .local-search-form__state {
            width: 130px !important;
          }
          .local-search-form__city {
            width: 165px !important;
          }
        }
      }
    }
  }
}
/* new changes from JP team */
body > img {
  display: block;
  position: absolute;
}
/* ======================================================
 --- 01 Brake
====================================================== */
br.sp,
br.tab {
  display: none;
  white-space: nowrap;
}

br.pc {
  display: inherit;
  white-space: nowrap;
}
/* SP and Tablet  */
@media screen and (min-width: 320px) and (max-width: 768px) {
  br.pc,
  br.tab {
    display: none;
    white-space: nowrap;
  }
  br.sp {
    display: inherit;
    white-space: nowrap;
  }
}
/* ----------- end for additional BREAK rules ----------- */

.search-again-help,
.ee-landing__filters__side__wrapper
/* Estee Edit Filter 機能 */ {
  display: none;
}

.page-sticky-footer .drawer-formatter__content .special-offer {
  margin-top: 30px;
}

@media screen and (min-width: 641px) {
  /* For PC-mode gNav section */

  .product-full .product-full__subline,
  .cart-items .products .product_subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 1.25;
  }
  .footer-main-menu .menu a {
    display: block;
    margin: 0;
    padding-left: 25px;
    height: 45px;
    border-bottom: 1px solid #1e2341;
    line-height: 45px;
  }
  /*
	.footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email {
		padding: 0;
		width: 100%;
	}
	.footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email .form-text,
	.footer-forms-location-sms-email-signup-elc-nodeblock .email-signup__section {
		width: 100%;
	}
	*/
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-find-us .form-text,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-sms .form-text,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email .form-text {
    float: left;
    width: 70%;
  }
}

@media screen and (max-width: 768px) and (min-width: 641px) {
  .footer-forms-location-sms-email-signup-elc-nodeblock .country_chooser__header,
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
  .footer-forms-location-sms-email-signup-elc-nodeblock .email_signup__header,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-header {
    font-size: 16px;
  }
}

@media screen and (max-width: 678px) {
  .checkout__sidebar .links_list > li .overlay-link {
    display: block;
    padding: 1em 0;
    text-align: center;
  }
  .checkout__sidebar .links_list > li .overlay-link {
    display: block;
    padding: 1em 0;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  /* By Tablet Vertical mode */

  h5.wishlist-confirm__message {
    font-size: 18px;
  }
}

@media only screen and (min-width: 769px) {
  /* More than Tablet Vertical mode - タブレット　タテ　以上 */

  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-change-location,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email {
    margin-right: 3%;
    width: 47%;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header {
    font-size: 18px;
  }
}

/* ======================================================
 --- 02 Quotation
====================================================== */
blockquote:lang(ja):before,
.pull-quote:lang(ja):before {
  content: '「';
}

blockquote:lang(ja):after,
.pull-quote:lang(ja):after {
  content: '」';
}

blockquote:lang(ja),
.pull-quote:lang(ja) {
  text-align: left;
}

@media screen and (min-width: 641px) {
  .ee-landing-tiles blockquote:lang(ja),
  .ee-landing-tiles .jp--quote {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 1em;
    line-height: 1.5;
  }
}
/* ----------- end for queote rules ----------- */

/* ======================================================
 --- 03 Text-align
====================================================== */
.ta_left {
  text-align: left;
}

.ta_right {
  text-align: right;
}

.ta_center {
  text-align: right;
}
/* ----------- end for text-align rules ----------- */

/* ======================================================
 --- 04 Display
====================================================== */
.display__inline {
  display: inline;
}

.display__none,
.search-again-help,
.ee-landing__filters__side__wrapper collapsed,
.checkout-progress {
  display: none;
}

.display__block {
  display: block;
}
/* ----------- end for display rules ----------- */

/* ======================================================
 --- 05 Font-size
====================================================== */
.search-product__sub-header,
.checkout .viewcart-header .messages {
  font-size: 15px;
}
/* All size (10px to 80px) */
.fs__10px {
  font-size: 10px !important;
}

.fs__11px {
  font-size: 11px !important;
}

.fs__12px {
  font-size: 12px !important;
}

.fs__13px {
  font-size: 13px !important;
}

.fs__14px {
  font-size: 14px !important;
}

.fs__15px {
  font-size: 15px !important;
}

.fs__16px {
  font-size: 16px !important;
}

.fs__17px {
  font-size: 17px !important;
}

.fs__18px {
  font-size: 18px !important;
}

.fs__19px {
  font-size: 19px !important;
}

.fs__20px {
  font-size: 20px !important;
}

.fs__21px {
  font-size: 21px !important;
}

.fs__22px {
  font-size: 22px !important;
}

.fs__23px {
  font-size: 23px !important;
}

.fs__24px {
  font-size: 24px !important;
}

.fs__25px {
  font-size: 25px !important;
}

.fs__26px {
  font-size: 26px !important;
}

.fs__27px {
  font-size: 27px !important;
}

.fs__28px {
  font-size: 28px !important;
}

.fs__29px {
  font-size: 29px !important;
}

.fs__30px {
  font-size: 30px !important;
}

.fs__31px {
  font-size: 31px !important;
}

.fs__32px {
  font-size: 32px !important;
}

.fs__33px {
  font-size: 33px !important;
}

.fs__34px {
  font-size: 34px !important;
}

.fs__35px {
  font-size: 35px !important;
}

.fs__36px {
  font-size: 36px !important;
}

.fs__37px {
  font-size: 37px !important;
}

.fs__38px {
  font-size: 38px !important;
}

.fs__39px {
  font-size: 39px !important;
}

.fs__40px {
  font-size: 40px !important;
}

.fs__41px {
  font-size: 41px !important;
}

.fs__42px {
  font-size: 42px !important;
}

.fs__43px {
  font-size: 43px !important;
}

.fs__44px {
  font-size: 44px !important;
}

.fs__45px {
  font-size: 45px !important;
}

.fs__46px {
  font-size: 46px !important;
}

.fs__47px {
  font-size: 47px !important;
}

.fs__48px {
  font-size: 48px !important;
}

.fs__49px {
  font-size: 49px !important;
}

.fs__50px {
  font-size: 50px !important;
}

.fs__51px {
  font-size: 51px !important;
}

.fs__52px {
  font-size: 52px !important;
}

.fs__53px {
  font-size: 53px !important;
}

.fs__54px {
  font-size: 54px !important;
}

.fs__55px {
  font-size: 55px !important;
}

.fs__56px {
  font-size: 56px !important;
}

.fs__57px {
  font-size: 57px !important;
}

.fs__58px {
  font-size: 58px !important;
}

.fs__59px {
  font-size: 59px !important;
}

.fs__60px {
  font-size: 60px !important;
}

.fs__61px {
  font-size: 61px !important;
}

.fs__62px {
  font-size: 62px !important;
}

.fs__63px {
  font-size: 63px !important;
}

.fs__64px {
  font-size: 64px !important;
}

.fs__65px {
  font-size: 65px !important;
}

.fs__66px {
  font-size: 66px !important;
}

.fs__67px {
  font-size: 67px !important;
}

.fs__68px {
  font-size: 68px !important;
}

.fs__69px {
  font-size: 69px !important;
}

.fs__70px {
  font-size: 70px !important;
}

.fs__71px {
  font-size: 71px !important;
}

.fs__72px {
  font-size: 72px !important;
}

.fs__73px {
  font-size: 73px !important;
}

.fs__74px {
  font-size: 74px !important;
}

.fs__75px {
  font-size: 75px !important;
}

.fs__76px {
  font-size: 76px !important;
}

.fs__77px {
  font-size: 77px !important;
}

.fs__78px {
  font-size: 78px !important;
}

.fs__79px {
  font-size: 79px !important;
}

.fs__80px {
  font-size: 80px !important;
}

/* ======================================================
 --- 06 Margin
====================================================== */

/* margin */
.m0 {
  margin: 0px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.m1 {
  margin: 1px !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.mr1 {
  margin-right: 1px !important;
}

.m2 {
  margin: 2px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.m3 {
  margin: 3px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.m4 {
  margin: 4px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.m5 {
  margin: 5px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.m6 {
  margin: 6px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.m7 {
  margin: 7px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.m8 {
  margin: 8px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.m9 {
  margin: 9px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.m45 {
  margin: 45px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.m50 {
  margin: 50px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.m55 {
  margin: 55px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.m60 {
  margin: 60px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.m65 {
  margin: 65px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.m70 {
  margin: 70px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.m75 {
  margin: 75px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.m80 {
  margin: 80px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.m85 {
  margin: 85px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.m90 {
  margin: 90px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.m95 {
  margin: 95px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.m100 {
  margin: 100px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}
/* padding */
.p0 {
  padding: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.p1 {
  padding: 1px !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pr1 {
  padding-right: 1px !important;
}

.p2 {
  padding: 2px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.p3 {
  padding: 3px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.p4 {
  padding: 4px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.p5 {
  padding: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.p6 {
  padding: 6px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.p7 {
  padding: 7px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.p40 {
  padding: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.p45 {
  padding: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.p50 {
  padding: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.p55 {
  padding: 55px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.p60 {
  padding: 60px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.p65 {
  padding: 65px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.p70 {
  padding: 70px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.p75 {
  padding: 75px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.p80 {
  padding: 80px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.p85 {
  padding: 85px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.p90 {
  padding: 90px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.p95 {
  padding: 95px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.p100 {
  padding: 100px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}
/* ======================================================
 --- 01 Navigation
====================================================== */
#cboxLoadedContent .sign-in-component {
  height: 565px;
}
/* ----------------------- end for Log-in Screen - Lightbox ----------------------- */

.page-navigation__hotswap .depth-2 {
  margin-top: 10px;
}

.page-navigation__supplemental a {
  font-size: 11px;
}

@media screen and (min-width: 641px) {
  .sign-in-component .sign-in-component__form--registration .sign-in-component__registration-o {
    width: 385px;
  }
  .elc-user-state-logged-in #colorbox #cboxClose,
  .elc-user-state-anonymous #colorbox #cboxClose {
    top: 25px;
    right: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .page-navigation .level-1,
  .page-navigation .depth-1 h3 {
    font-size: 15px;
    line-height: 27px;
  }
  .page-navigation {
    margin-top: 0;
  }
}
/* ----------------------- Mail Magazine Registration Lightbox ----------------------- */

/* ======================================================
 --- 02 Logo
====================================================== */

h5.wishlist-confirm__message {
  font-size: 18px;
  margin-top: 30px !important;
}

/* ======================================================
 --- 03 Viewcart
====================================================== */
.checkout .left.checkout__content .viewcart-panel__content {
  margin-top: 3em;
}

h3.product-full__title,
h4.product-full__subtitle,
.product-full__subtitle .item-count:before {
  vertical-align: text-bottom;
}

.checkout__sidebar .order-summary-panel__title {
  padding-bottom: 14px;
  border-bottom: none;
}

.checkout .right.checkout__sidebar #checkout-sidebar #customer-service .customer-service-header {
  font-size: 25px;
}

.checkout .right.checkout__sidebar #checkout-sidebar #customer-service {
  margin: 0 auto;
  max-width: 15em;
  width: 100%;
}

.checkout-progress {
  display: none;
}

.ee-social-share__tooltip-content {
  font-size: 15px;
  width: 7em;
}

.customer-service .sidebar-page__content h2 {
  font-size: 20px;
}
/* ---  for all device --- */

@media only screen and (min-width: 641px) {
  .checkout-page-title,
  .samples-page .samples-panel__title,
  .account-order h3.account-page__header {
    font-size: 30px;
  }
  .item-count-number {
    font-size: 26px;
  }
  .checkout__header-item-count,
  .account-order h4.section-head__header {
    font-size: 25px;
  }
  .cart-items .products .product_subline {
    font-size: 16px;
  }
  .sign-in-component .sign-in-component__header {
    font-size: 24px;
  }
  .text-product-listing-names-sub {
    line-height: 1.25;
  }
  /* account/index.tmpl 会員情報 > お気に入り :: nth-child はIE8非対応 */

  a.text-product-listing-names-link h3:nth-child(3) {
    font-size: 13px;
    line-height: 1.25;
  }
  .account-favorites__list > li,
  .account-replenish__list > li,
  .account-purchases__list > li {
    width: 185px;
  }
  .product-full .product-full__subline,
  .cart-items .products .product_subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 1.25;
  }
}
/* --- for SP --- */

@media only screen and (min-width: 769px) {
  .checkout__sidebar {
    padding: 35px 9.5px;
  }
}
/* --- for tablet vertical --- */

/* ======================================================
 --- 04 Footer Common
====================================================== */
.instagram,
.youtube {
  display: none;
}

.page-footer,
body .country-chooser__selector {
  font-size: 14px;
}

.page-footer .form-text,
.recommended-products__list > li [type='submit'] {
  font-size: 12px;
}

.footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
  line-height: 1.4;
}

.country-chooser__selector {
  padding: 9px 12px 5px 10px;
}

.is-alternate .form-text {
  padding: 11px 12px 9px 10px;
}

.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
  margin: inherit;
}

.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a.shoplist_footer,
a.shoplist_footer,
.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a.shoplist_footer:hover,
a.shoplist_footer:hover {
  background-position: 0 -3487px;
}

.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a.shoplist_footer,
a.shoplist_footer {
  margin: 0;
  padding-top: 22px;
  padding-bottom: 22px;
  @media #{$large-up} {
    padding-left: 26px;
  }
}

.country-chooser .menu li a {
  line-height: 40px;
}

@media only screen and (min-width: 641px) {
  .footer-forms-location-sms-email-signup-elc-nodeblock .email-signup .email-signup__error,
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
    margin-top: 0;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
    padding: 22px 0;
  }
  /* Footer カスタマーサービス 項 */

  .footer-need-help .menu-container li:last-child {
    /* 電話番号 部 */
    margin-top: 1em;
  }
  /* Footer 店舗について 項 */

  .footer__sns,
  .footer__sns p {
    overflow: hidden;
  }
  .footer__sns .atcosme {
    float: left;
    overflow: hidden;
    padding: 10px 5px;
    width: 100px;
    height: 35px;
    background-position: 1px;
    text-align: center;
    line-height: 15px;
  }
  .footer__sns .atcosme {
    margin-right: 2em;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
  .footer-find-us .menu .level-2,
  .footer__sns a {
    line-height: 77px;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .is_open .menu-container a,
  .footer-tel:before {
    padding-left: 33px;
  }
  .footer-tel {
    display: block;
    margin: 1em 0;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .is_open .menu-container a {
    line-height: 38.5px;
  }
  .menu .level-2 {
    padding-left: 33px;
  }
  .footer-find-us .menu .level-2,
  .footer__sns a {
    display: block;
    padding-left: inherit;
  }
  .footer__sns a img {
    vertical-align: middle;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-find-us {
    width: 100%;
  }
  .footer-legal a {
    display: block;
    line-height: 44px;
  }
}

@media screen and (max-width: 780px) {
  .node-9402 {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 1090px) {
  .footer-legal {
    height: 88px;
  }
}

@media screen and (max-width: 1090px) {
  .page-sticky-footer__inner .menu em.gothic {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .node-9240 {
    min-height: 88px;
    background-color: #000;
  }
}

@media screen and (max-width: 2048px) {
  /* iPad 4 ヨコまで */

  .ee-landing__text .link--bold {
    display: block;
    padding: 1em;
  }
}

.formatter-estee-edit {
  .formatter-estee-edit__content .basic-textarea-v1 p {
    font-size: 15px;
    line-height: 1.86;
    letter-spacing: 0.01em;
  }
  blockquote {
    font-size: 28px;
    line-height: 1.35;
    letter-spacing: -0.02em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .ee-landing__tile .ee-landing__text h4 {
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .pc-carousel-formatter,
  .slick-slider,
  .ee-tout-wrapper {
    margin-bottom: 0;
  }
  h1.header-meta__headline,
  h1.headline--primary {
    font-size: 90px;
    font-weight: 100;
  }
  h1.header-meta__headline {
    line-height: 0.85;
  }
  .header-meta__subhead {
    font-size: 22px;
    line-height: 1.41;
    letter-spacing: -0.02em;
  }
  .header-meta .header-meta__headline {
    margin-bottom: 0;
  }
  .estee-edit__header {
    margin-bottom: 4em;
  }
  .ee--subhead__jp {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.02em;
  }
  span.edith1sub__jp {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.41;
    display: block;
    margin: 14px 0;
    letter-spacing: -0.02em;
  }
  .ee-mb-tile__text h4 {
    font-size: 25px;
    line-height: 1.24;
  }
  .ee-mb-tile__text {
    margin-top: 8px;
  }
  .ee-mb-tile__text p:first-of-type {
    font-size: 13px;
    margin-top: 5px;
    line-height: 1.41;
  }
  .mb-slideshow__slide__headline {
    font-size: 36px;
    line-height: 1.41;
  }
  .mb-slideshow__slide-content--portrait .mb-slideshow__slide__copy,
  .mb-slideshow__slide-content--landscape .mb-slideshow__slide__copy {
    text-align: left;
  }
  .ee-disco-more .ee-disco-more__headline {
    font-size: 25px;
    line-height: 1.2;
    margin-top: 5px;
  }
  @media only screen and (min-width: 900px) {
    .ee-disco-more .ee-disco-more__headline {
      font-size: 25px;
    }
  }
}

.section-esearch {
  .el-search-block__links a {
    font-size: 25px;
  }
  .el-search-block__links h5 {
    letter-spacing: 0.08em;
    font-size: 13px;
  }
  .el-search-block input[type='text'].form-text {
    font-size: 55px;
  }
  .el-search-block {
    margin-top: 1em;
  }
}

body.front {
  .hero-block__promo-copy .text-promo__subhead,
  .hero-block__promo-copy .text-promo__subcopy {
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 14px;
  }
  @media screen and (min-width: 320px) and (max-width: 640px) {
    h5.wishlist-confirm__message {
      font-size: 18px;
      margin-top: 30px !important;
    }
  }
  @media screen and (min-width: 641px) and (max-width: 768px) {
    h5.wishlist-confirm__message {
      font-size: 18px;
      margin-top: 30px !important;
    }
  }
}

.renutriv_section_wrapper .side_nav_list li,
.renutriv_section_wrapper #renutriv_section-1 div.content a.video,
.renutriv_section_wrapper a.buttonless.experience_now,
.renutriv_section_wrapper #renutriv_section-2 .content p.first,
.renutriv_section_wrapper div.side_nav li a,
.renutriv_section_wrapper .boutique p,
#rn_container #renutriv_section-4 li p,
#rn_container #renutriv_section-3 .content li p,
.renutriv_section_wrapper #renutriv_section-1 .content p,
#rn_container #renutriv_section-2 .content p.first {
  font-family: $bb-roman, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
    sans-serif;
}
/* ------------------- .gothic + Bauer Bodoni ------------------- */

.renutriv_section_wrapper h3.propoText.propoLineheight.propoTextReady,
#rn_container .propoTextReady,
#rn_container #renutriv_section-1 div.content a.video {
  font-family: $bb-roman, '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
    'MS PMincho', 'serif';
}
/* ------------------- .mincho + Bauer Bodoni ------------------- */

.bodoni,
.renutriv_section_wrapper .propoTextReady,
.renutriv_section_wrapper h3.propoText.propoLineheight.propoTextReady,
.brand-renutriv .discover-more__header {
  font-family: $bb-roman;
}
/* ------------------- Bauer Bodoni only ------------------- */

#rn_container #renutriv_section-3 .content li p {
  font-size: 20px;
}

#rn_container .content .experience_now {
  margin: 30px 0 15px;
}

.compare-checkbox-wrapper,
div#mpp__filter-tab {
  display: none;
}
/* ----------- All ----------- */

@media screen and (min-width: 641px) {
  .product-full__button-container {
    margin-top: 18px;
  }
  body.brand-renutriv h3.product-full__subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    font-size: 17px;
  }
  .product-full .product-full__subline {
    font-size: 23px;
  }
  .product-full .product-full__subline,
  .cart-items .products .product_subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 1.45;
  }
  h6.spp-product__mini-bag-sub-header {
    line-height: 1.25;
  }
  .spp-product__mini-bag-quantity {
    width: 65px;
  }
  .spp-product__mini-bag-add-button {
    padding: 0 5px;
    max-width: 155px;
    width: 100%;
  }
  .BVRRRatingNumberPrefix,
  .BVRRTitle {
    font-size: 35px;
  }
  /*
		.spp-product__anchor-help,
		.spp-product__anchor.is_expanded .spp-product__anchor-nav li.spp-product__anchor-help {
			visibility: hidden;
		}
		*/
  /*
		.spp-product__anchor-nav {
			padding-bottom: 17px;
		}
		*/
  p.discover-more_subHeader {
    font-size: 20px;
  }
}

.section-store-locator.device-pc .store-locator {
  margin-top: 1em;
}
/* ----------- All ----------- */

@media screen and (min-width: 641px) {
  .section-store-locator.device-pc .store-locator .store-locator__title {
    margin: 1em 0 0.5em;
    border-bottom: none;
    letter-spacing: -0.02em;
    font-weight: 800;
    font-size: 26px;
    line-height: 1.25;
  }
}

.optima,
.spp-product__sub-header,
.BVDI_QT .BVRRRatingOverall .BVRRRatingNormalOutOf,
.BVDI_QT .BVRRRatingOverall .BVRRRatingRangeNumber,
.node-elc-mpp h1.header__headline.headline--large span,
h1.header-meta__headline,
h1.headline--primary,
span.eng_jp_font,
.product-full__title,
h1.header-meta__headline,
h1.headline--primary {
  font-weight: 100;
}

.page-sticky-footer__inner {
  border-bottom: 1px solid #1e2341;
}

.offers-email-signup-form,
.offers-register-form,
.offers-sign-in-form,
.special-offers-tray .gift-wrap {
  display: none;
}

.special-offers-tray .special-offers-tray__col {
  float: right;
}

.special-offers-tray .special-offer {
  width: 60%;
}

.special-offers-tray .special-offer-benefits {
  width: 40%;
}

.page-sticky-footer .drawer-formatter__content .special-offer .special-offer__img {
  margin: 0 40px 15px 15px;
}

.drawer-formatter__close {
  z-index: 1;
}

.special-offer__text {
  overflow: hidden;
}

.page-sticky-footer .drawer-formatter__content .special-offer .special-offer__text {
  margin: 8px 0 20px;
}

.page-sticky-footer .drawer-formatter__content .special-offer .button {
  position: relative;
  top: 0;
  left: 0;
}

@media (min-width: 641px) and (max-width: 769px) {
  .special-offer__text {
    overflow: initial;
  }
}

.footer-tel:before {
  content: '電話番号：';
  float: left;
}

.block {
  display: block;
  float: left;
}

.ee-social-share__tooltip-content {
  font-size: 15px;
  width: 7em;
}

.customer-service .sidebar-page__content h2 {
  font-size: 20px;
}

product-full__title {
  font-family: 'OptimaDisplayLight';
}

@media screen and (min-width: 641px) {
  .product-full .product-full__subline,
  .cart-items products .product_subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 1.25;
  }
}

.email_popover__social_icons .facebook {
  display: inline;
}

.instagram,
.youtube {
  display: none;
}

br.sp,
br.tab {
  display: none;
  white-space: nowrap;
}

br.pc {
  display: inherit;
  white-space: nowrap;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  br.pc,
  br.tab {
    display: none;
    white-space: nowrap;
  }
  br.sp {
    display: inherit;
    white-space: nowrap;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  br.sp,
  br.pc {
    display: none;
    white-space: nowrap;
  }
  br.tab {
    display: inherit;
    white-space: nowrap;
  }
}
/* ヘッダー ロゴ部 */

h5.wishlist-confirm__message {
  font-size: 18px;
  margin-top: 30px !important;
}

.ta_left {
  text-align: left;
}

.display__inline {
  display: inline;
}
/* 引用符 */

blockquote:lang(ja):before,
.pull-quote:lang(ja):before {
  content: '「';
}

blockquote:lang(ja):after,
.pull-quote:lang(ja):after {
  content: '」';
}

blockquote:lang(ja),
.pull-quote:lang(ja) {
  text-align: left;
}

@media screen and (min-width: 641px) {
  .ee-landing-tiles blockquote:lang(ja),
  .ee-landing-tiles .jp--quote {
    font-size: 1em;
    line-height: 1.5;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
/* ----------------------- グローバルナビゲーション 用 Setting ここから ----------------------- */

.page-navigation .depth-1 h3 {
  font-family: '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho', 'serif';
}

.page-navigation__hotswap .depth-2 {
  margin-top: 10px;
}

.page-navigation__supplemental {
  .responsive-carousel-nav .flexslider {
    margin: 0;
    .slides > li {
      margin-left: 0;
    }
  }
  a {
    font-size: 11px;
  }
}

@media screen and (min-width: 641px) {
  .page-navigation .depth-1 h3 {
    font-size: 20px;
  }
}
/* 会員登録／ログイン ポップアップ */

@media screen and (min-width: 641px) {
  .sign-in-component .sign-in-component__form--registration .sign-in-component__registration-o {
    width: 385px;
  }
}
/* ログイン画面 Lightbox */

#cboxLoadedContent .sign-in-component {
  height: 565px;
}
/* ----------------------- フッター部 セクション タイトル 用 Setting ここから ----------------------- */

.footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
.footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
  line-height: 1.4;
}

.page-footer,
body .country-chooser__selector {
  font-size: 14px;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';
}

.country-chooser__selector {
  padding: 9px 12px 5px 10px;
}

.is-alternate .form-text {
  padding: 11px 12px 9px 10px;
}

.page-footer .form-text,
.recommended-products__list > li [type='submit'] {
  font-size: 12px;
}

@media only screen and (min-width: 641px) {
  .footer-forms-location-sms-email-signup-elc-nodeblock .email-signup .email-signup__error,
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
    margin-top: 0;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock a {
    padding: 22px 0;
  }
  /* Footer カスタマーサービス 項 */

  .footer-need-help .menu-container li:last-child {
    /* 電話番号 部 */
    margin-top: 1em;
  }
  /* Footer 店舗について 項 */

  .footer__sns,
  .footer__sns p {
    overflow: hidden;
  }
  .footer__sns .atcosme {
    padding: 10px 5px;
    overflow: hidden;
    height: 35px;
    line-height: 15px;
    width: 100px;
    text-align: center;
    float: left;
    background-position: 1px;
  }
  .footer__sns .atcosme {
    margin-right: 2em;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
  .footer-find-us .menu .level-2,
  .footer__sns a {
    line-height: 77px;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .country_chooser__header,
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header,
  .footer-forms-location-sms-email-signup-elc-nodeblock .email_signup__header,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-header {
    font-size: 16px;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .is_open .menu-container a,
  .footer-tel:before {
    padding-left: 33px;
  }
  .footer-tel {
    margin: 1em 0;
    display: block;
  }
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .is_open .menu-container a {
    line-height: 38.5px;
  }
  .menu .level-2 {
    padding-left: 33px;
  }
  .footer-find-us .menu .level-2,
  .footer__sns a {
    display: block;
    padding-left: inherit;
  }
  .footer__sns a img {
    vertical-align: middle;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-find-us {
    width: 100%;
  }
  .footer-legal a {
    line-height: 44px;
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 1090px) {
  .footer-legal {
    height: 88px;
  }
}

@media only screen and (min-width: 769px) {
  .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .footer-header {
    font-size: 18px;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-change-location,
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email {
    width: 47%;
    margin-right: 3%;
  }
}

@media screen and (max-width: 1090px) {
  .page-sticky-footer__inner .menu em.gothic {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .node-9240 {
    min-height: 88px;
    background-color: #000;
  }
}

@media screen and (max-width: 2048px) {
  /* iPad 4 ヨコまで */

  .ee-landing__text .link--bold {
    display: block;
    padding: 1em;
  }
}
/* ----------------------- store-locator 店舗検索 ----------------------- */

.section-store-locator.device-pc .store-locator {
  margin-top: 1em;
}

@media screen and (min-width: 641px) {
  .section-store-locator.device-pc .store-locator .store-locator__title {
    font-weight: 800;
    margin: 1em 0 0.5em;
    border-bottom: none;
    font-size: 26px;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
/* ----------------------- MPP ----------------------- */
/* MPP 買い物かごへいれる　ボタン */

.button--dark {
  font-size: 11.5px;
  letter-spacing: 0.05em;
}
/* MPP カテゴリータイトル */

.headline--products,
.mpp__header {
  font-size: 30px;
}
/* MPP 和文製品名 */

.product_subline.cart-item__product-name a,
/* viewcart.tmpl　お買い物かごページ */
.product_brief__header.product_brief__sub-line {
  line-height: 1.25;
}
/* viewcart.tmpl　お買い物かごページ */

.product_brief__header.product_brief__sub-line,
.cart-item .product_subline,
.text-product-listing-names-sub {
  font-size: 14px;
}
/* MPP 価格 */

.product_brief__price-size {
  letter-spacing: 0.03em;
}
/* ----------------------- 検索欄 用 Setting ここから ----------------------- */

.el-search-block__links a {
  font-size: 25px;
}

.el-search-block__links h5 {
  letter-spacing: 0.08em;
  font-size: 13px;
}

.el-search-block input[type='text'].form-text {
  font-size: 55px;
}

.search-again-help
/* 検索結果 Need Help? を非表示 */,
ee-landing__filters__side__wrapper collapsed {
  display: none;
}

.el-search-block {
  margin-top: 1em;
}
/* ----------------------- SPP 用 Setting ここから ----------------------- */
/* Compar - 比較する */

.compare-checkbox-wrapper,
div#mpp__filter-tab {
  display: none;
}

@media screen and (min-width: 641px) {
  .product-full__button-container {
    margin-top: 18px;
  }
  body.brand-renutriv h3.product-full__subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    font-size: 17px;
  }
  .product-full .product-full__subline {
    font-size: 23px;
  }
  .product-full .product-full__subline,
  .cart-items products .product_subline,
  body #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 1.45;
  }
  h6.spp-product__mini-bag-sub-header {
    line-height: 1.25;
  }
  .spp-product__mini-bag-quantity {
    width: 65px;
  }
  .spp-product__mini-bag-add-button {
    max-width: 155px;
    width: 100%;
    padding: 0 5px;
  }
  .BVRRRatingNumberPrefix,
  .BVRRTitle {
    font-size: 35px;
  }
  /* .spp-product__anchor-help,
  .spp-product__anchor.is_expanded .spp-product__anchor-nav li.spp-product__anchor-help {
    visibility: hidden;
  } */
  /* .spp-product__anchor-nav {
    padding-bottom: 17px;
  } */
  p.discover-more_subHeader {
    font-size: 20px;
  }
}
/* ----------------------- AboutRN用 Settings ここから ----------------------- */

.renutriv_section_wrapper .side_nav_list li,
.renutriv_section_wrapper #renutriv_section-1 div.content a.video,
.renutriv_section_wrapper a.buttonless.experience_now,
.renutriv_section_wrapper #renutriv_section-2 .content p.first,
.renutriv_section_wrapper div.side_nav li a,
.renutriv_section_wrapper .boutique p,
#rn_container #renutriv_section-4 li p,
#rn_container #renutriv_section-3 .content li p,
.renutriv_section_wrapper #renutriv_section-1 .content p,
#rn_container #renutriv_section-2 .content p.first {
  font-family: $bb-roman, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
    sans-serif;
}

#rn_container #renutriv_section-3 .content li p {
  font-size: 20px;
}

.renutriv_section_wrapper h3.propoText.propoLineheight.propoTextReady,
#rn_container .propoTextReady,
#rn_container #renutriv_section-1 div.content a.video {
  font-family: $bb-roman, '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
    'MS PMincho', 'serif';
}

#rn_container .content .experience_now {
  margin: 30px 0 15px;
}

.renutriv_section_wrapper .propoTextReady,
.renutriv_section_wrapper h3.propoText.propoLineheight.propoTextReady {
  font-family: $bb-roman;
}
/* ----------------------- font用 Settings ここから ----------------------- */

.ee-article-products__header h2,
body .mpp__header,
body .el-search-block input[type='text'].form-text,
body #colorbox.colorbox__quickshop .quickshop h3,
body #colorbox.colorbox__quickshop .quickshop__sub-header {
  /* Optima 入り */
  font-family: 'OptimaDisplayLight', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
    'MS PMincho', 'serif';
}

.product-full__subtitle,
.product-full__title,
.optima {
  font-weight: 100;
  text-transform: capitalize;
}

.discover-more__header,
.bodoni {
  font-family: $bb-roman;
}

.samples-page .available,
.search-product__sub-header,
p.discover-more_subHeader,
.brand-renutriv .product-full__attribute p,
.brand-renutriv .page-main p,
.brand-renutriv .spp-product__details .spp-product__details-description p,
.brand-renutriv .spp-product__details .spp-product__details-attribute p,
.brand-renutriv .spp-product__details .spp-product__details-attribute__label,
.brand-renutriv .product-full__detail-link,
.brand-renutriv a.selectBox,
.brand-renutriv .product-full,
.brand-renutriv .page-main,
.section-store-locator.device-pc .store-locator .doors-results__pager .doors-results__pager-view-all,
.checkout .viewcart-header .messages,
.gothic {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, sans-serif;
}

.search-product__sub-header,
.checkout .viewcart-header .messages {
  font-size: 15px;
}

.mincho,
.checkout__panel-title,
.spp-product__mini-bag-sub-header,
.BVDI_QT .BVRRRatingOverall .BVRRRatingNumberPrefix,
.brand-renutriv .spp-product__details .spp-product__details-header,
.brand-renutriv .discover_more__product-sub-header,
.brand-renutriv .page-main h5,
.checkout__sidebar h2,
.section-store-locator.device-pc .store-locator .door-row .door-row__name,
.section-store-locator.device-pc .store-locator .store-results-header,
.text-product-listing-names-main,
#colorbox.colorbox__quickshop .quickshop__tabs-control .quickshop__tab-control.quickshop__tab-control--2:first-child {
  font-family: '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho', 'serif';
}
/* ----------------------- viewcart.tmpl お買い物かご ページ以降 用 Settings ここから ----------------------- */

.checkout .left.checkout__content .viewcart-panel__content {
  margin-top: 3em;
}

h3.product-full__title,
h4.product-full__subtitle,
.product-full__subtitle .item-count:before {
  vertical-align: text-bottom;
}

.checkout__sidebar .order-summary-panel__title {
  padding-bottom: 14px;
  border-bottom: none;
}

.checkout .right.checkout__sidebar #checkout-sidebar #customer-service .customer-service-header {
  font-size: 25px;
}

.checkout .right.checkout__sidebar #checkout-sidebar #customer-service {
  margin: 0 auto;
  max-width: 15em;
  width: 100%;
}

@media only screen and (min-width: 641px) {
  .checkout-page-title,
  .samples-page .samples-panel__title,
  .account-order h3.account-page__header {
    font-size: 30px;
  }
  .item-count-number {
    font-size: 26px;
  }
  .checkout__header-item-count,
  .account-order h4.section-head__header {
    font-size: 25px;
  }
  .cart-items products .product_subline {
    font-size: 16px;
  }
  .sign-in-component .sign-in-component__header {
    font-size: 24px;
  }
  .text-product-listing-names-sub {
    line-height: 1.25;
  }
  /* account/index.tmpl 会員情報 > お気に入り :: nth-child はIE8非対応 */

  a.text-product-listing-names-link h3:nth-child(3) {
    font-size: 13px;
    line-height: 1.25;
  }
  .account-favorites__list > li,
  .account-replenish__list > li,
  .account-purchases__list > li {
    width: 185px;
  }
}

@media only screen and (min-width: 769px) {
  .checkout__sidebar {
    padding: 35px 9.5px;
  }
}
/* ----------------------- viewcart.tmpl ここまで ----------------------- */
/* -----------------------homeslide 用 Settings ここから----------------------- */

.elc-user-state-logged-in #colorbox #cboxClose,
.elc-user-state-anonymous #colorbox #cboxClose {
  right: 25px;
  top: 25px;
}

.hero-block__promo-copy .text-promo__subhead {
  font-size: 14px;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';
  letter-spacing: 0em;
  text-transform: uppercase;
}

.hero-block__promo-copy .text-promo__subcopy {
  font-size: 14px;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';
  letter-spacing: 0em;
  text-transform: uppercase;
}

.optima {
  font-family: 'OptimaDisplayLight';
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  h5.wishlist-confirm__message {
    font-size: 18px;
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  h5.wishlist-confirm__message {
    font-size: 18px;
    margin-top: 30px !important;
  }
}
/* -----------------------Estee Edit 用 Settings ここから----------------------- */
/* Japanese Font Settings */

.ee-landing__tile .ee-landing__text h4 {
  line-height: 1.1;
  margin-top: 10px;
  margin-bottom: 15px;
}

h1.header-meta__headline,
h1.headline--primary {
  font-family: 'OptimaDisplayLight';
  font-size: 90px;
  font-weight: 100;
}

h1.header-meta__headline {
  line-height: 0.85;
}

.formatter-estee-edit .header-meta__subhead {
  font-size: 22px;
  line-height: 1.41;
  letter-spacing: -0.02em;
  font-family: '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'HG明朝E', 'ＭＳ Ｐ明朝', 'MS PMincho',
    'serif';
}

.header-meta .header-meta__headline {
  margin-bottom: 0;
}

.estee-edit__header {
  margin-bottom: 4em;
}
/* for Smartphone */

@media screen and (min-width: 320px) and (max-width: 640px) {
  br.sp {
    display: block !important;
  }
  br.pc {
    display: none !important;
  }
  .ee-landing__tile .ee-landing__text h4 {
    font-size: 21px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .ee-landing__tile .ee-landing__text .ee-subhead {
    font-size: 13px;
  }
  .ee-hero {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .ee-hero img {
    width: 100%;
  }
  .ee-hero__caption {
    width: 96%;
    margin: 5px auto 0;
    font-size: 12px;
    padding-left: 10px;
  }
  h1.header-meta__headline {
    font-size: 55px;
  }
  .header-meta .header-meta__headline {
    margin-bottom: 0.3em;
  }
  span.edith1sub {
    font-size: 14px;
    margin: 16px 0 0;
  }
  .formatter-estee-edit .header-meta__subhead {
    font-size: 18px;
    width: 94%;
    margin: 0 auto;
  }
  .formatter-estee-edit__content .basic-textarea-v1 {
    width: 90%;
    margin: 0 auto;
  }
  body blockquote {
    font-size: 22px;
    line-height: 1.41;
    width: 90%;
    text-align: left;
    margin: 26px auto !important;
  }
  .ee-article-image {
    text-align: center;
    max-width: 100%;
    margin: 0 auto 8px;
  }
  .ee-article-image img {
    width: 94%;
  }
  span.edith1sub__jp {
    font-size: 14px;
  }
  .mb-slideshow__slide-content--portrait .mb-slideshow__slide__copy,
  .mb-slideshow__slide-content--landscape .mb-slideshow__slide__copy {
    width: 90%;
    margin: 0 auto;
  }
  .mb-slideshow__slide__headline {
    font-size: 21px;
    text-align: center;
  }
  .mb-slideshow__slide__caption p {
    font-size: 13px;
    line-height: 1.56;
  }
  .ee-mb-tile__text {
    width: 90%;
    margin: 0 auto;
  }
  .ee-disco-more .ee-disco-more__headline-main {
    margin-bottom: 5px;
  }
  .ee-disco-more__image {
    margin: 0 auto 10px;
  }
  #colorbox.colorbox__quickshop .quickshop__tabs-control .quickshop__tab-control.quickshop__tab-control--2:first-child {
    font-size: 30px;
    width: 100%;
    letter-spacing: -0.005em;
  }
}
/* -----------------------Estee Edit 用 Settings ここまで----------------------- */
/* /checkout/checkout.tmpl 内　購入のフロー部　下記が現時点でのもの暫定的にトルツメ
1. 配送・お支払い 2. お支払い（クレジットカードなら） 3.購入確認 4.注文確認
*/

.checkout-progress {
  display: none;
}

em {
  font-weight: 700;
  font-style: normal;
}

@media only screen and (min-width: 641px) {
  input[type='checkbox'] ~ label {
    padding-top: 0;
  }
}

.sign-in-component .sign-in-component__form--registration .sign-in-component__terms p {
  display: block;
}

.sign-in-component .sign-in-component__form--registration .sign-in-component__registration-options .text--form-help p {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, sans-serif;
}

.sign-in-component .sign-in-component__form--registration .sign-in-component__registration-options .text--form-help p {
  line-height: 1.8em;
}

input[type='checkbox'] ~ label a,
.sign-in-component a {
  display: inline;
  border-bottom: solid 1px transparent;
  color: #213393;
  text-decoration: none;
}

input[type='checkbox'] ~ label a:hover,
.sign-in-component a:hover {
  border-bottom: solid 1px #213393;
}

sup {
  top: -0.5em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.page-branding__logo,
.is-alternate .page-branding__logo,
.brand-aerin .is-alternate .page-branding__logo,
.brand-renutriv .is-alternate .page-branding__logo,
.is-sticky .page-branding__logo {
  background-repeat: no-repeat;
}

.brand-renutriv .page-main h3.product_brief__header {
  font-family: $bb-roman !important;
}

.brand-renutriv .page-main .node-elc-nodeblock .hero-block--renutriv .cta-button-wrapper .button--inverted {
  border: 1px solid #c3a36e;
  color: #c3a36e;
}

.brand-renutriv .page-main h3.product_brief__header.product_brief__sub-line {
  font-family: '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho', 'serif' !important;
}

.formatter-estee-edit__content {
  .pc-carousel-formatter {
    .slick-carousel {
      .header-meta {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        p:nth-child(3) {
          font-size: 17px;
        }
      }
    }
  }
}

.section-customer-service {
  .cs-quick-info {
    &__title--full {
      font-size: 12px;
    }
    .cs__livechat {
      display: none;
    }
  }
}
