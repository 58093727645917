.contact-form {
  .headline--section {
    .hdr-right {
      float: right;
      font-size: 13px;
      padding-top: 15px;
    }
  }
  .contact-form__section--question-types {
    max-width: 100%;
    .contact-form__question-type {
      float: left;
      .contact-form__sub-question-types {
        display: block !important;
        padding-top: 13px;
        padding-left: 0px;
        input {
          width: 87%;
        }
      }
    }
    .cform-1 {
      width: 42%;
      padding-right: 10%;
    }
    .cform-2,
    .cform-3 {
      width: 25%;
    }
  }
}
