// Reusable extendables
%checkout-item-column {
  width: 100%;
  clear: both;
  // keep 100% until after ipad portrait
  @media #{$medium-up} {
    float: $ldirection;
    clear: none;
  }
}
%half-checkout-item-left {
  @extend %checkout-item-column;
  clear: $ldirection;
  @media #{$medium-up} {
    width: 50%;
    padding-#{$rdirection}: $cart-spacing / 2;
    #{$ie8} & {
      clear: $ldirection;
    }
  }
}
%half-checkout-item-right {
  @extend %checkout-item-column;
  @media #{$medium-up} {
    width: 50%;
    padding-#{$ldirection}: $cart-spacing / 2;
  }
}
%third-checkout-item-left {
  @extend %checkout-item-column;
  clear: $ldirection;
  @media #{$medium-up} {
    width: 33%;
    padding-#{$rdirection}: $cart-spacing / 2;
  }
}
%third-checkout-item-middle {
  @extend %checkout-item-column;
  @media #{$medium-up} {
    width: 33%;
    padding-#{$rdirection}: $cart-spacing / 2;
    padding-#{$ldirection}: $cart-spacing / 2;
  }
}
%third-checkout-item-right {
  @extend %checkout-item-column;
  @media #{$medium-up} {
    width: 33%;
    padding-#{$ldirection}: $cart-spacing / 2;
  }
}
%checkout-button {
  width: 100%;
  clear: both;
  @media #{$medium-up} {
    width: auto;
  }
}
