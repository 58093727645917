.checkout--single-page.shipping .checkout-progress__shipping,
.checkout--single-page.order_review .checkout-progress__review,
.checkout--single-page.confirm .checkout-progress__confirm {
  font-weight: bold;
}

.checkout {
  ol.checkout-progress li.payment {
    width: 6.5em;
  }
  a:focus {
    outline: none;
  }
  .right.checkout__sidebar {
    margin-top: $sidebarWidth;
    #need-help-panel {
      margin-bottom: 25px;
      .phone a {
        text-decoration: none;
      }
      .hyphen {
        margin: 0 5px;
      }
    }
    #offer-code-panel {
      margin-top: 20px;
    }
    .order-summary-panel__title {
      padding: 20px 0;
    }
    #customer-service {
      width: $sidebarWidth * 7.2;
      padding: 8px;
      text-align: center;
    }
    .order-summary-panel {
      margin-top: $sidebarWidth;
    }
    .offer-code-panel {
      margin-top: 40px;
    }
    .order-summary__table {
      th {
        text-align: left;
        padding: $sidebarWidth/8 0;
      }
      td {
        padding: $sidebarWidth/8 0;
      }
    }
    a.go-shopping {
      width: 19em;
    }
    #offer_code input[name='OFFER_CODE'] {
      width: 100%;
    }
    .offer-code-panel input[type='submit'] {
      margin: 10px 0;
      width: 100%;
    }
  }
  .order-summary__content {
    div {
      margin: 0;
    }
    .order-summary__total-label {
      border-bottom: 0px;
      border-top: 1px solid #cccccc;
    }
    .order-summary__total-value {
      border-bottom: 0px;
      border-top: 1px solid #cccccc;
    }
  }
  .left.checkout__content {
    .single-message {
      text-align: center;
      margin-bottom: 20px;
      color: red;
    }
    .wp-messages {
      margin: 20px 0 0 0;
      font-size: 15px;
    }
    .wp-messages.not-qualify {
      color: red;
    }
    #sign-in-panel .single-message {
      color: red;
    }
    #promo-panel {
      margin-bottom: 25px;
    }
    .checkout-header {
      margin-bottom: 0px;
      clear: both;
      float: left;
      width: 100%;
      h2 {
        float: left;
        font-size: 22px;
        padding-bottom: 20px;
      }
      #header-item-count {
        float: right;
        font-size: 12px;
        margin-top: 10px;
        .item-count-language {
          letter-spacing: 1.8px;
        }
      }
      .checkout__header-item-count:before {
        content: none;
      }
    }
    .cart-items {
      .cart-header {
        border-top: none;
        margin-top: 0;
        .qty {
          width: 10%;
          text-align: center;
        }
        .price {
          width: 12%;
        }
        .total column {
          float: right;
        }
        .total {
          width: 10%;
          float: right;
        }
      }
      .sub__total {
        margin: 0.8em 0;
        float: right;
      }
      .free_sample {
        font-wieght: normal;
        text-transform: capitalize;
      }
      .cart-item {
        border: none;
      }
      .cart_item_data {
        border-bottom: 1px solid $itemborder;
      }
    }
    .viewcart-panel__content {
      border-bottom: 1px solid $itemborder;
    }
    .shipping-panel {
      .single-message {
        color: red;
      }
      .ship_method_container {
        clear: both;
        width: 100%;
      }
      .shipform_fields {
        width: 100%;
        margin-bottom: 20px;
        select {
          margin-bottom: 5px;
        }
      }
      p.required_mark {
        display: inline;
      }
      .required-text {
        opacity: 0.5;
      }
      &#shipping-panel .address-form {
        fieldset.address {
          clear: both;
          input {
            color: black;
          }
          .search-hint {
            opacity: 0.5;
            text-transform: none;
          }
        }
        .form-item.title {
          display: block;
          width: 100%;
        }
        input {
          text-transform: uppercase;
        }
      }
      .new-address {
        h3.shipping-edit-address-content__title {
          float: left;
        }
        .sub-section.new-address {
          clear: both;
        }
        .shipping_pls_select {
          float: left;
          margin: 0px 0 11px 5px;
          line-height: 14px;
        }
        .billing-address-form {
          input {
            text-transform: uppercase;
          }
        }
      }
      .edit-address {
        .sub-section.new-address {
          margin-top: 20px;
        }
      }
    }
    .confirmation-panel {
      h3 {
        margin-top: 20px;
      }
      #confirmation-number {
        a {
          text-decoration: underline;
        }
      }
    }
    #billing-address-info-panel {
      #bill_to_shipping_container {
        .form-item.radio-buttons {
          label {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
    .panel.collapsed > header {
      border-bottom: 1px solid #cccccc;
    }
  }
  #shipping-page-info-panel {
    .checkout-header {
      margin-bottom: $sidebarWidth/2;
    }
    .shipping-info {
      .address-container {
        float: left;
        margin: 0 4em 0.8em 1.5em;
        width: $sidebarWidth * 5;
      }
      .address_controls {
        float: left;
        #choose-address {
          margin-top: 0.8em;
          width: $sidebarWidth * 9;
        }
        a {
          text-decoration: underline;
        }
        select {
          width: 400px;
          letter-spacing: normal;
        }
      }
      .address-form__title-container {
        width: 60%;
      }
      .right {
        padding-left: 1.2em;
      }
      .address-note {
        padding: 0.4em 0;
        color: red;
      }
    }
    .billing-info {
      @extend .shipping-info;
    }
    .delivery-info {
      #delivery-instructions {
        width: 50%;
      }
      select {
        width: 50%;
      }
    }
  }
  .giftwrap-info {
    .checkout__subtitle {
      font-size: 1em;
      margin-top: 0.6em;
    }
    .gift-options-wrapper {
      width: 70%;
      .form_element {
        margin-top: 0.7em;
        width: 46%;
        float: left;
        padding-bottom: 0.7em;
      }
      .right {
        padding-left: 0.8em;
      }
      .giftmsg {
        width: 95%;
      }
    }
  }
  .section-email-promotions {
    width: 100%;
  }
  .pc_email_promo_container {
    label {
      width: 100%;
    }
    input[type='text'] {
      width: 50%;
    }
  }
  address {
    clear: both;
    font-style: normal;
  }
  #container_payment_option_credit {
    iframe {
      width: $sidebarWidth * 8.5;
      height: $sidebarWidth * 7;
    }
  }
  ol.checkout-progress {
    margin: 2em 0;
    li {
      float: left;
      margin-left: 1.5em;
      width: 10.5em;
    }
  }
  #confirmation-page {
    width: 100%;
    margin-top: 3.5em;
    padding: 0;
    .sign-in-confirmation-panel #forgot_password {
      text-transform: none;
      font-size: 14px;
    }
    .right.checkout__sidebar {
      margin-top: 0em;
    }
    #completed_transaction_signin {
      .email {
        display: block;
      }
      .password {
        padding-left: 0;
      }
    }
    #registration-panel {
      .messages {
        #error_no_shipping_info {
          display: none;
        }
      }
    }
  }
  .viewcart-panel {
    margin-top: 91px;
    #header-item-count {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1.8px;
    }
    .checkout-table {
      width: 100%;
    }
    #promo-message img {
      width: 100%;
    }
    &__content {
      .signin-to-see-cart {
        padding-top: 20px;
      }
      .cart-items.products:last-child .cart-item.product {
        border-bottom: none;
      }
    }
  }
  .cart-item__price {
    letter-spacing: 0.1em;
    &.free_sample {
      font-weight: normal;
    }
  }
  .sample {
    .price.cart-item__price {
      font-size: 11px;
      font-weight: normal;
      letter-spacing: 0px;
    }
  }
  .cart-item__qty {
    width: 70px;
    text-align: center;
    padding: 12px;
    border: none;
  }
  input[type='password'],
  input[type='text'] {
    width: 100%;
  }
  .billing-address-from-fields {
    width: 100%;
    .name-fields label {
      width: auto;
    }
    .address.qas {
      clear: both;
      .search-hint {
        opacity: 0.5;
        text-transform: none;
      }
    }
    .city-state-zip {
      .form-item.city {
        padding-left: 0;
      }
    }
    input[type='text'] {
      text-transform: uppercase;
    }
  }
  .default-shipping {
    clear: both;
    width: 100% !important;
    margin-right: 0 !important;
  }
  #offer_code input[name='OFFER_CODE'] {
    width: 50%;
  }
  .offer-code-panel {
    input[type='submit'] {
      margin: 0 20px;
    }
  }
  .btn {
    padding: 0 15px;
  }
  a.choose-location {
    &.button {
      border: solid 1px $color-navy;
    }
  }
  .btn.continue-checkout,
  .btn.go-shopping,
  .confirmation-panel__print-buttons .btn {
    background: #040a2b;
    color: white;
  }
  .btn.continue-checkout {
    display: none;
  }
  #bottom-viewcart-buttons .btn.continue-checkout {
    display: block;
  }
  section.panel header h2 {
    color: #000;
    font-weight: normal;
    font-family: $font2;
    letter-spacing: normal;
    padding-left: 0;
  }
  .panel header h2,
  .panel header .item-count {
    font-size: 1.4em;
    padding: 0.3em 0.5em;
    font-weight: bold;
    border-width: 0;
    margin: 0;
    line-height: 1.2em;
  }
  .prev-next {
    display: none;
  }
  .replen_notice {
    width: 100%;
  }
  #shipping-panel-edit {
    bottom: 5px;
  }
  #payment-panel {
    .payment-container {
      padding-bottom: 20px;
      label {
        width: 45%;
        vertical-align: top;
      }
      &__option {
        &--rakuten ~ label {
          width: 100%;
        }
      }
    }
  }
  .checkout__header-item-count#header-item-count {
    font-family: $font2;
    font-weight: normal;
  }
  .birth-month-container {
    .select-birth-month {
      width: 25%;
    }
    .birthday-panel__title {
      font-size: 14px;
    }
    .checkout-birthday {
      &__descriptive-text {
        padding-top: 10px;
        font-size: 12px;
      }
    }
  }
}

.viewcart-buttons .continue-checkout.disabled,
.viewcart-buttons .choose-samples-and.disabled {
  display: none;
}

.tab-content {
  .product-list {
    overflow: hidden;
    min-height: 250px;
    .product {
      margin-bottom: 20px;
    }
  }
}

#wishlist .email_wish_popover #cboxLoadedContent {
  height: auto !important;
}

.samples-buttons.top {
  display: none;
}

#viewcart {
  .cart-item__qty {
    @include swap_direction(padding, 12px 12px 0 0);
  }
  .recommended-item {
    float: left;
    text-align: center;
    position: relative;
    width: 30%;
    font-family: $font;
    height: 560px;
    margin: 0 10px;
    .product_name {
      font-size: 22px;
      letter-spacing: normal;
    }
    .product_subline {
      font-size: 14px;
      letter-spacing: normal;
      font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, sans-serif;
    }
    .addtobag {
      position: absolute;
      bottom: 0;
      margin: 0 10px;
    }
    .description.column {
      width: 140px;
      margin: 0 auto;
      div {
        margin: 15px 0;
      }
    }
    .product_subname {
      display: none;
    }
    .formatted_Taxed_Price {
      font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, sans-serif;
    }
    .formatted__Price {
      font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, sans-serif;
      font-weight: bold;
    }
  }
  #colorbox.samples-overlay {
    top: 0 !important;
    background: white;
    .prev-next {
      display: none;
    }
  }
}

#index {
  .left.checkout__content {
    padding-top: 60px;
  }
  .right.checkout__sidebar {
    #viewcart-panel {
      margin-top: 61px;
      .cart-item__qty form#cart {
        display: inline-block;
      }
      .engraving_option {
        border-top: none !important;
        .engraving-cart-control {
          display: none;
        }
      }
    }
  }
  .cart-item__qty {
    @include swap_direction(padding, 12px 0 0 12px);
    letter-spacing: 1px;
    &__engraving-max {
      padding-top: 35px;
      font-size: 0.8em;
    }
  }
}

.ee-article-products__list .sku-brief-editorial {
  min-height: 460px;
  position: relative;
  .sku-brief-editorial__button-wrapper {
    position: absolute;
    bottom: 30px;
    left: 28%;
  }
  .link--bold {
    position: absolute;
    bottom: 0px;
    left: 31%;
  }
}

.continue-shopping {
  position: static;
  top: auto;
  left: 0;
  margin: 30px 0 0;
  text-align: left;
}

.checkout .right.checkout__sidebar {
  margin-top: 0;
  .add-button {
    margin-top: 20px;
  }
  .tab-pane footer {
    text-align: center;
    margin-top: 20px;
  }
}

.viewcart .checkout__sidebar:after {
  width: 34.76563%;
  left: 65.23438%;
}

.ie8 {
  .registration-page {
    .form-item {
      input[type='radio'].field {
        width: auto;
      }
      label {
        float: left;
      }
      &.title_container,
      &.sex_container {
        input[type='radio'].field {
          margin-top: 3px;
        }
        span {
          float: left;
        }
        .title-text {
          width: 100%;
          float: left;
        }
      }
    }
    .email-promotions__container .radio.email-promotions__content {
      input {
        margin-top: 7px;
      }
      label {
        width: 80%;
      }
    }
    .sms-promotions__content .radio.pc_email_promo_container input {
      margin-top: 7px;
    }
  }
  .page-utilities__cart .page-utilities__cart-count {
    min-width: 44px;
  }
  .viewcart {
    .checkout__sidebar {
      width: 34.76563%;
      left: 65.23438%;
      background-color: #f2f3f4;
    }
    .checkout__content {
      width: 65.23438%;
    }
  }
  .checkout {
    input[type='radio'] ~ label {
      float: left;
    }
    .address-form .title.form-item {
      .field {
        width: auto;
        color: black;
      }
      label {
        float: left;
      }
    }
  }
  #payment-panel .payment-container input[type='radio'] {
    float: none;
  }
  section.gift-options__content {
    clear: both;
  }
  .billing-address {
    label {
      float: left;
    }
    .billing-address-from-fields {
      clear: both;
      .address.qas {
        clear: both;
        .search-hint {
          opacity: 0.5;
          text-transform: none;
        }
      }
    }
  }
}

.panel > header {
  border-bottom: 2px solid #040a2b;
  margin: 0 0 16px 0;
}

.need-help-panel .need-help-panel__title,
.checkout__sidebar .links-panel__title,
.samples h3 {
  font-size: 22px;
  text-transform: none;
  font-weight: normal;
  padding-bottom: 10px;
  letter-spacing: normal;
}

.panel > .content {
  margin: 0;
  padding: 0;
}

.need-help-panel,
.links-panel .links_list > li,
.links-panel__title,
.samples-panel__title {
  text-align: left;
}

.panel .content h4 a,
.links-panel .links_list > li a,
.links-panel .links_list > li .overlay-link {
  font-weight: bold;
  text-decoration: underline;
}

#favorites-pane .content h4,
#past-purchases-pane .content h4 {
  font-size: 16px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.checkout__sidebar .tab-content {
  display: block;
  overflow: hidden;
  margin-bottom: 16px;
  padding-bottom: 16px;
  clear: both;
  #samples-pane #samples-panel {
    margin-top: 10px;
    .remaining {
      margin-bottom: 20px;
      text-transform: none;
    }
  }
  #favorites-pane {
    #favorites-panel {
      p.favorites-panel__favorites-sign-in a {
        text-decoration: underline;
        font-weight: bold;
      }
      .details {
        font-size: 12px;
      }
    }
  }
  #past-purchases-pane #past-purchases-panel {
    p a {
      text-decoration: underline;
      font-weight: bold;
    }
    .details {
      font-size: 12px;
    }
  }
}

.checkout__sidebar .tab-bar li {
  a {
    color: #cccccc;
    cursor: pointer;
  }
  &.first {
    width: 26%;
  }
  &.second {
    width: 32%;
  }
  &.third {
    width: 42%;
  }
}

.checkout__sidebar .tab-bar li.active {
  color: #040a2b;
}

.checkout__sidebar .tab-pane {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.checkout__sidebar .tab-pane.active {
  display: block !important;
  opacity: 1;
  height: auto;
}

.el-search-block .loading {
  height: 31px;
}

.samples-overlay {
  .product {
    height: 475px;
    position: relative;
    width: 28%;
    margin: 0 2.5%;
    .details .skus {
      width: 80%;
      .sample-select-button {
        width: 100%;
        padding: 0;
      }
    }
  }
  .return-link {
    position: absolute;
  }
  #samples-panel {
    header {
      display: none;
    }
    .remaining .available {
      font-size: 15px;
      text-transform: none;
    }
  }
}

.samples-overlay .product {
  .details {
    color: black;
    margin-top: 15px;
    .skus {
      margin-top: 20px;
      position: absolute;
      bottom: 0px;
      margin-left: 8%;
    }
  }
}

#viewcart #colorbox #cboxClose {
  top: 10px;
}

.checkout__return-user {
  min-height: 360px;
  padding-left: 24px !important;
  input[type='email'] {
    width: 100%;
    display: block;
    max-width: 225px;
    color: #040a2b;
  }
  #checkout_signin #forgot_password {
    text-transform: none;
    font-size: 12px;
  }
  .form-item {
    margin-bottom: 20px;
  }
  #form-err-note li {
    list-style-type: none;
  }
}

.checkout__new-account {
  min-height: 360px;
  input[type='email'] {
    width: 100%;
    display: block;
    max-width: 225px;
    color: #040a2b;
  }
}

.checkout__panel-title,
.samples-page .samples-panel__title {
  padding-bottom: 16px;
  margin-bottom: 0;
  border-bottom: none;
}

#checkout_registration_panel .accepted-privacy-policy .label-content {
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.75px;
}

.links-panel .content a {
  text-decoration: underline;
}

.checkout {
  .overlay-link {
    text-transform: none;
  }
  .selectBox {
    width: auto;
  }
}

.checkout__sidebar {
  .product-img {
    max-width: 140px;
    margin: 0;
    padding: 0;
    float: left;
    width: 40%;
    text-align: center;
  }
  .details {
    margin: 0 0 0 40%;
    padding: 0;
    width: 55%;
    line-height: 25px;
  }
  .tab-bar {
    display: block;
    overflow: hidden;
    border-bottom: 2px solid #040a2b;
    margin-bottom: 16px;
    padding-bottom: 5px;
    li {
      font-size: 15px;
      letter-spacing: 0;
      margin: 0;
      width: 33%;
      float: left;
      cursor: pointer;
      text-align: center;
    }
    li .active {
      color: #040a2b;
    }
  }
  .tab-content {
    clear: both;
    display: block;
    border-bottom: none;
    .samples {
      .mobile_hidden {
        text-align: left;
        a {
          border-bottom: 1px solid #dbdbdb;
        }
      }
      .product {
        .product-img img {
          width: auto;
          height: 100px;
        }
      }
    }
    .favs {
      .product {
        .product-img img {
          width: auto;
          height: 100px;
          margin-top: 15px;
        }
        .shades-list .skus {
          margin-top: 0px;
        }
        .details .product-name {
          padding-top: 15px;
        }
      }
    }
    .pp {
      .product {
        .product-img img {
          width: auto;
          height: 100px;
          margin-top: 15px;
        }
        .shades-list .skus {
          margin-top: 0px;
        }
        .details .product-name {
          padding-top: 15px;
        }
      }
    }
  }
  .tab-pane {
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    transition: opacity 1s ease;
    &.active {
      display: block;
      opacity: 1;
      height: auto;
    }
    header {
      display: none;
    }
  }
  .offer {
    padding: 0;
    h3 {
      &.product-name {
        padding-top: 15px;
        font-size: 20px;
      }
    }
    .skus {
      margin-top: 0px;
    }
  }
}

.form-item.expires-month {
  width: 100%;
  padding-right: 0;
  margin-bottom: 10px;
  select {
    width: 32%;
    float: left;
  }
  span {
    float: left;
    margin: 1%;
  }
}

.cvv {
  width: 33%;
  margin: 0;
}

.save_payment {
  float: left;
  margin-left: 7px;
  margin-top: -4px;
}

.payment-form {
  #continue-btn {
    clear: both;
    float: right;
    margin: 25px 0;
  }
}

#billing-address-display {
  float: left;
}

div#gift-options-display header {
  margin-top: 20px;
}

.checkout__subtitle {
  margin: 5px 0 5px;
  font-size: 15px;
}

#registration-panel {
  .checkout-registration__email-address {
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
    .example-user-email {
      text-transform: none;
      font-weight: normal;
      opacity: 0.5;
    }
  }
  .checkout-registration__password {
    width: 100%;
    clear: both;
    padding-left: 0;
    input {
      width: 40%;
    }
    .password {
      margin-bottom: 10px;
    }
    label[data-required]:before {
      display: none;
    }
  }
  .checkout-registration__password-hint {
    width: 40%;
    padding-right: 0 10px;
    float: none;
  }
}

#payment-panel {
  input[type='checkbox'] {
    position: relative;
  }
  .payment-edit-content {
    .field-are-required {
      position: relative;
    }
  }
  .continue-button-wrapper {
    &.rakuten {
      input {
        width: 300px;
        background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
        color: transparent;
        outline: 0;
      }
    }
  }
  .form-item.expiry select:first-child {
    margin-right: 6px;
  }
  .payment-form {
    width: 50%;
  }
  .form-item.cvv {
    padding-left: 0;
  }
}

section.viewcart {
  .tab-pane {
    .swatch {
      border-radius: 11px;
      display: inline-block;
      height: 20px;
      vertical-align: middle;
      width: 20px;
      float: left;
    }
    .shade {
      margin-left: 30px;
    }
  }
}

//view cart btns
#viewcart .viewcart-buttons-panel.panel {
  margin: 25px 0;
  text-align: center;
  .continue-buttons {
    float: right;
    width: 100%;
    .choose-samples-checkout {
      display: inline-block !important;
    }
    .continue-shopping.text_link {
      margin-right: 35%;
      margin-top: 0px;
      position: relative;
      float: left;
    }
  }
}

#viewcart-panel {
  .viewcart-header {
    padding-bottom: 10px;
  }
  .viewcart-panel__title {
    font-size: 25px;
  }
}

.card-number input,
.cvv input {
  width: 40%;
}

#checkout_payment .form-item.expiry select {
  width: 45%;
  height: 3em;
}

div.column section.panel div.form_element,
div.column section.panel div.form-item {
  width: 100%;
  float: none;
}

.viewcart-buttons-panel .continue-checkout-btns {
  float: right;
}

#confirmation-panel header h2 {
  font-size: 1.4em;
  padding: 0.3em 0;
  border-width: 0;
  margin: 0;
  line-height: 1.2em;
}

.form-item.expiry label {
  width: 100%;
}

.samples-panel {
  .product-subhead {
    font-size: 12px;
  }
}

#gift-options {
  #gift-options-h {
    display: inline-block;
    padding-bottom: 25px;
  }
  .edit {
    position: relative;
    padding: 0 10px;
    bottom: 0px;
  }
}

#privacy_policy_content h3 {
  font-size: 15px;
  font-weight: bold;
}

.form-item.card-message input {
  margin-bottom: 10px;
}

@media #{$medium-up} {
  .checkout__sidebar .cart-item__desc {
    width: 60%;
    padding-right: 50px;
  }
  .cart-item__desc {
    font-size: 12px;
    padding-right: 10px;
    width: 30%;
  }
  .cart-item__price {
    font-size: 12px;
    font-weight: normal;
  }
  .cart-item__total {
    font-weight: normal;
    width: 18%;
  }
}

.offer-code__one-offer-only {
  font-weight: normal;
}

#confirm {
  .print-buttons {
    float: left;
  }
  .return-link-container {
    float: right;
    .button {
      text-decoration: underline;
    }
  }
}

@media #{$large-up} {
  .viewcart {
    .checkout__content {
      width: 65.23438%;
    }
    .checkout__sidebar {
      width: 34.76563%;
      padding: 35px 16px;
    }
    #need-help-panel,
    .tab-switcher,
    #past-purchases-pane {
      display: block !important;
    }
  }
}

#samples {
  .samples-panel__title {
    text-align: center;
  }
  .samples-page {
    .product-list {
      width: 100%;
    }
    .remaining {
      width: 100%;
      p {
        font-size: 15px;
      }
    }
    .prev-next.mobile_hidden {
      display: none;
    }
    .samples-buttons.bottom {
      border-top: none;
    }
    .add-button {
      display: none;
    }
    .viewcart-buttons-panel.panel {
      text-align: center;
      .continue-checkout {
        background: #040a2b;
        color: white;
      }
    }
  }
}

#confirmation-page {
  .confirmation-panel__actions {
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
}

// Samples Overlay
.samples-overlay {
  .samples-panel__heading {
    font-size: 18px;
    text-transform: none;
  }
}
// Hide Sample heading in sidebar
.checkout__sidebar {
  .tab-content {
    .samples-panel__heading {
      display: none;
    }
  }
}

.remaining {
  .samples-panel__promo-text {
    display: inline-block;
    &.large {
      display: none;
    }
    @media #{$large-up} {
      display: none;
      &.large {
        display: inline-block;
      }
    }
  }
}
