.video_youtube {
  width: 100% !important;
}

@media only screen and (min-width: 641px) {
  .headline--page {
    padding-top: 40px !important;
  }
}
/* 249010 - fix for logo alignment issue */
.ee-tout__logo {
  @media #{$medium-up} {
    margin-top: 0px;
  }
}

.ee-landing-carousel {
  margin-top: 0px;
}

.ee-tout__image {
  @media #{$medium-up} {
    top: 110px;
  }
}

.cart-confirm.one_time_hide {
  display: none !important;
}

.sku-brief-editorial__size-price-reviews {
  .text--bold.sku-brief-editorial__size {
    text-transform: none;
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP_japanese.jpg');
}

// 2018 Creative refresh
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.utility-nav__account {
  @media #{$medium-up} {
    margin-right: 18px;
  }
  @media #{$xlarge-up} {
    margin-right: 10px;
  }
  &.utility-nav__loyalty {
    .user-loyalty-state {
      display: none;
    }
  }
}

.page-utilities {
  .utility-nav__cart {
    @media #{$medium-up} {
      left: 32px;
    }
    @media #{$xlarge-up} {
      left: 45px;
    }
  }
  .is-search &__search-button &__search-icon {
    margin-left: 2px;
  }
  &__search-button {
    @media #{$medium-up} {
      right: 65px;
      padding-left: 16px;
    }
  }
}

.page-header.alt-color-white {
  .page-branding {
    .trust-mark-elc-nodeblock {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3.png');
      }
    }
    &__logo {
      height: 31px;
    }
  }
  .header-items {
    @media #{$xlarge-up} {
      padding: 17px 0;
      min-height: 65px;
    }
  }
  .page-navigation {
    margin-top: 0;
    ul.menu {
      max-height: none;
    }
  }
}

.section-re-nutriv,
.brand-renutriv {
  .page-wrapper {
    .page-navigation__menu {
      .menu__item--link a {
        color: $color-navy;
      }
    }
  }
}

.page-wrapper {
  .page-header {
    @media #{$medium-up} {
      background: $color-white;
    }
  }
}

.product-vto__youcam-module-container {
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    @media #{$medium-up} {
      z-index: 98;
    }
  }
}
.zero-dollar-overlay {
  text-align: center;
  .zero-dollar-buttons {
    a {
      display: inline-block;
      width: 170px;
      background-color: $color-black;
      color: $color-white;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.zdc {
  &_popup {
    #cboxLoadedContent {
      margin: 0;
    }
  }
}
