// This file will serve as a "translation" stylesheet, extending what we can
// from styles existing for the US site, on selectors that are equivalent to
// what are provide for the US site (for which the estee_base/scss files were
// developed).

// * WHAT IF THE LOCALISED CHECKOUT ISNT BASED ON THE US SITE *
// * THESE STYLES SHOULD NOT BE IN THE BASE THEME *
// * THE SHOULD BE IN THE US LOCALISATION *
// * US !== ELC_BASE *

$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$header-line: 2px solid $color-navy; // line used everywhere
$cart-line: 1px solid $color-light-gray; // line used everywhere
$cart-spacing: 16px; // common shared margin/paddings

$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(360px / $cart-container-width);
$cart-sidebar-width: percentage(360px / $cart-container-width); // this will override

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

$mobile-panel-bg: $color-gray-cs-side;

// HYJACK
$mobile-outer-gutter-root: 0;

// REIMPORT CHECKOUT-SHARED
// AS WE NEED OUR OWN VERSION OF THE VARIBLES TO BE USED

$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$header-line: 2px solid $color-navy; // line used everywhere
$cart-line: 1px solid $color-light-gray; // line used everywhere
$cart-spacing: 16px; // common shared margin/paddings

$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(360px / $cart-container-width);
$cart-sidebar-width: percentage(360px / $cart-container-width); // this will override

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

$mobile-panel-bg: $color-gray-cs-side;

// HYJACK
$mobile-outer-gutter-root: 0;
$sidebarWidth: 3em;
$itemborder: #cccccc;

$font: 'Optima Display Roman', 'OptimaDisplayLight', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3',
  'ＭＳ Ｐ明朝', 'MS PMincho', 'serif', 'sans-serif', 'Tahoma', 'arial';
$font2: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';

#cboxWrapper {
  background: #fff;
  #cboxLoadedContent {
    color: #03092a;
  }
}

#cboxLoadedContent {
  #address-form-popover {
    input[type='text'],
    input[type='tel'] {
      color: $color-black;
    }
    .address_form_container {
      input[type='text'] {
        height: 40px;
      }
      select {
        height: 40px;
        line-height: 40px;
      }
      .address-form__postal-code-container span.fieldset-note {
        top: 30px;
      }
    }
  }
}

.adpl {
  .password.checkout-registration__password {
    .label-class {
      display: none;
    }
    input[type='password'] + label {
      pointer-events: none;
    }
  }
}

.sign-in-panel.adpl {
  input[type='email'].js-label-mode + label:before,
  input[type='tel'].js-label-mode + label:before,
  input[type='password'].js-label-mode + label:before,
  input[type='text'].js-label-mode + label:before {
    content: attr(data-required) attr(alt);
  }
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

.checkout {
  #registration-panel {
    .checkout-registration__password {
      label[data-required]:before {
        display: inline-block;
      }
    }
  }
}

#viewcart {
  .checkout {
    select {
      width: auto;
    }
  }
  .cart-item {
    &__total {
      position: absolute;
    }
  }
}

@import "checkout-spc";

@import 'checkout-forms';

@import 'checkout-shipping';
