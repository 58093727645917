/* Power reviews */

/* Japn local font-family */
@mixin jp-local-font-family {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';
}

.review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal,
      &.pr-snippet-compact,
      &.pr-snippet-standard {
        .pr-snippet-read-and-write {
          @include jp-local-font-family();
          a,
          span {
            @include jp-local-font-family();
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-read-and-write {
            .pr-snippet-write-review-link {
              @include jp-local-font-family();
            }
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-sort {
          @include jp-local-font-family();
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-rd-review-headline {
            @include jp-local-font-family();
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            @include jp-local-font-family();
          }
          .pr-rd-right {
            .pr-rd-reviewer-details {
              p,
              time {
                @include jp-local-font-family();
              }
              p {
                .pr-rd-bold {
                  @include jp-local-font-family();
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          .pr-rd-description-text {
            @include jp-local-font-family();
          }
          .pr-rd-merchant-response-headline {
            strong {
              @include jp-local-font-family();
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              @include jp-local-font-family();
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                @include jp-local-font-family();
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                @include jp-local-font-family();
                &:before {
                  content: '参考になった (';
                  visibility: visible;
                }
                &:after {
                  content: ')';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                @include jp-local-font-family();
                border: none;
                &:before {
                  content: '参考にならない (';
                  visibility: visible;
                }
                &:after {
                  content: ')';
                  visibility: visible;
                }
              }
            }
          }
          .pr-rd-bottomline {
            span {
              @include jp-local-font-family();
            }
          }
          .pr-rd-flag-review-container {
            a {
              @include jp-local-font-family();
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              @include jp-local-font-family();
            }
            .pr-control-label,
            .pr-flag-review-label {
              @include jp-local-font-family();
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            @include jp-local-font-family();
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            @include jp-local-font-family();
          }
        }
      }
      .pr-rd-no-reviews {
        @include jp-local-font-family();
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='年齢'] {
          &::before {
            content: 'フィルタリングする:';
            font-size: 12px;
            font-family: $akzidenz;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='ダー使用歴'] {
          display: none;
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: '並び替え: ';
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            .pr-search-icon {
              &::after {
                content: '検索';
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 68%;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' ／ 5';
            }
          }
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                top: 27px;
              }
            }
          }
        }
      }
    }
  }
}

#power_review_container {
  #pr-write {
    .p-w-r {
      span {
        @include jp-local-font-family();
      }
      .pr-submit {
        .pr-subscript {
          @include jp-local-font-family();
        }
      }
      .pr-comments-form-group {
        #pr-comments {
          font-family: $akzidenz;
        }
      }
    }
  }
}
