// see _checkout-forms.scss for extendables
.shipping-panel {
  @include pie-clearfix;
  position: relative;
  .select-menu {
    width: 100%;
    overflow: hidden;
  }
  .messages {
    text-align: left;
    .MESSAGE {
      color: inherit; //$color-red;
    }
    .close_link {
      margin-right: 10px;
    }
  }
  /* feeling sick? me too !important; */
  html[data-useragent*='iPad'] & {
    select.SELECTED_ADDRESS_ID {
      display: block !important;
    }
  }
  .required-text {
    margin-bottom: $cart-spacing;
  }
  header#gift-message-h {
    margin-top: $cart-spacing;
  }
  #delivery-options-display {
    width: 50%;
    display: block;
    float: right;
  }
  input[type='radio'] ~ label,
  input[type='radio'] ~ .label {
    display: inline-block;
  }
  .edit-shipping-radio {
    clear: both;
    padding: 12px 0;
  }
  #gift_wrap_text {
    font-size: 10px;
  }
}

.shipping-edit-address-content__content,
.address-form {
  @include clearfix;
}

.shipping-edit-address-content__title {
}

// because designer
.checkout .address-form {
  @media #{$large-up} {
    width: 100%;
  }
  .form-item {
    padding-left: 0;
  }
}

#shipping-panel .address-form .form-item.title,
.billing-address-form .billing-address-from-fields .form-item.title {
  display: none;
}

.addresses {
  .form-item.address1,
  .form-item.address2,
  .form-item.address3 {
    width: 100%;
  }
}

.default-shipping {
  .address-form & {
    margin-bottom: 0; // nothing in here, remove paddin's
  }
}

.name-fields {
  .form-item {
  }
}

///
/// Gift Options
///

.gift-options__content {
  @include pie-clearfix;
  .panel {
    @media #{$small-only} {
      header {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media #{$medium-up} {
    margin-top: $cart-spacing;
  }
  img {
    display: none;
    vertical-align: top;
    width: 43%;
    margin-left: 0.5em;

    // move up on desktop
    @media #{$large-up} {
      display: inline-block;
      margin-top: 0;
      margin-left: 1em;
      width: 13em;
    }
  }
  .sub-section {
    margin-bottom: $cart-spacing;
  }
}

.giftwrap__is-gift {
  display: inline-block;
  width: auto !important;
}

.card-message {
  margin-bottom: $cart-spacing/2;
}

.card-message-to,
.card-message-from {
  // @extend %half-checkout-item;
}

.card-message-to {
  @extend %half-checkout-item-left;
}

.card-message-from {
  @extend %half-checkout-item-right;
}

///
/// View after submissions
///

.shipping-address-display,
.gift-options-display {
}

.shipping-address-display {
  @extend %half-checkout-item-left;
}

.gift-options-display {
  @extend %half-checkout-item-right;
}

.shipping-address-display__addresses {
}

.address-form {
  .ship-type {
    display: none;
  }
  .address_lookup_wrapper {
    border: 1px solid red;
    padding: 10px;
    margin: 10px 0;
    font-size: 12px;
    h4 {
      font-size: 12px;
    }
  }
}

.msg-order-email {
  @media #{$large-up} {
    display: none;
  }
}

.country {
  .form-item label {
    width: 100%;
  }
}

.manual-address a {
  text-decoration: underline;
}

#registration-panel {
  .show-password.checkout-registration__show-password {
    width: 100% !important;
  }
  .security_question {
    margin-bottom: 12px;
  }
  .password {
    margin-bottom: 5px;
  }
}

.address {
  width: 100%;
  #profile-postal input {
    width: 25%;
  }
  .fieldset-note {
    position: absolute;
    top: 14px;
    left: 170px;
    .form-submit.address_lookup_submit {
      height: auto !important;
    }
  }
  #profile-postal.postal_code_container {
    width: 100% !important;
  }
  .city_container {
    padding-top: 24px;
  }
  #address .state_container {
    padding-bottom: 13px;
    select {
      height: 3em;
    }
  }
}

.lookup_submit_note {
  display: block;
  float: right;
  bottom: 45px;
  position: relative;
  right: 35px;
  font-size: 12px;
}

.finished .content {
  opacity: 1;
}

.form_element.state_container {
  padding-top: 0;
}

.form_element,
.form-item {
  padding-top: 10px;
}

.checkout {
  .address {
    #profile-postal {
      input {
        width: 20%;
      }
    }
  }
}

#delivery-info-shipping-note,
.pickpoint-payment-note {
  font-size: 11.5px;
  color: $color-darker-gray;
  margin: 5px 0;
}

#checkout_delivery {
  .delivery-date-wrapper,
  .delivery-time-wrapper {
    select:disabled {
      cursor: not-allowed;
    }
  }
}
