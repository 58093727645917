@charset "UTF-8";

body {
  font-family: $font_1;
  .page-main {
    font-family: $font_1;
  }
  .headline--subsection {
    font-family: $font_1;
  }
  .text--bold,
  .text-small-header,
  .text--short,
  .text--short-copy,
  .text-short-body-copy,
  .text--tiny,
  .text--tiny-copy,
  .text--long-copy,
  .text-long-body-copy,
  .text--form-help,
  .text-form-submit {
    font-family: $font_1;
  }
  label {
    font-family: $font_1;
  }
  p {
    font-family: $font_1;
  }
  .text--form-help p {
    font-family: $font_1;
  }
  .link,
  .link--bold,
  .section-head__link {
    font-family: $font_1;
  }
  .tabs.primary a,
  .tabs-component a {
    font-family: $font_1;
  }
  /* Page Navigation */
  .page-utilities__help-text,
  .page-utilities__account-text,
  .page-utilities__signin-text,
  .page-utilities__cart-text,
  .utility-item-text,
  .page-navigation .level-1,
  .page-navigation .level-2,
  .page-navigation .level-3,
  .page-navigation__menu_text {
    font-family: $font_1;
  }
  .back-to-top {
    font-family: $font_1;
  }
  /* Promotions */
  .promo-unit__headline {
    font-family: $font_2;
  }
  .promo-unit__link {
    font-family: $font_1;
  }
  .promotions .nodeblock-signup-form ul {
    font-family: $font_1;
  }
  /* Discover */
  .discover_more__product-sub-header {
    font-family: $font_2;
  }
  /* Buttons */
  .button--dark,
  .button--light,
  .button--light-border,
  .btn,
  .cta,
  .continue-button-wrapper input[type='submit'],
  .checkout .submit input[type='submit'],
  .sample-select-button.selected,
  .viewcart-buttons .continue-checkout,
  .viewcart-buttons .go-shopping {
    font-family: $font_1;
  }
  /* Input Box */
  .text-form-text,
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'],
  textarea,
  .form-text,
  select,
  a.selectBox,
  a.selectbox {
    font-family: $font_1;
  }
  .selectBox-options li a {
    font-family: $font_1;
  }
  .form-submit,
  .form-submit--light,
  .form-submit--selected,
  .form-submit--unselected,
  .form-submit--active,
  .form-submit--inactive {
    font-family: $font_1;
  }
  /* Checkout */
  .cart-item__qty,
  .cart-header-text,
  .cart-header,
  .cart-item__color,
  .cart-item__remove-form,
  .cart-item__price,
  .cart-item__total {
    font-family: $font_1;
  }
  .checkout label {
    font-family: $font_1;
  }
  .checkout {
    .sms-terms-and-conditions {
      a {
        font-family: $font_1;
      }
    }
  }
  .checkout__subtitle {
    font-family: $font_1;
  }
  .order-summary__subtotal-label,
  .order-summary__subtotal-value,
  .order-summary__shipping form,
  .order-summary__shipping-method,
  .order-summary__shipping-value,
  .order-summary__total-label,
  .order-summary__total-value {
    font-family: $font_1;
  }
  .discount,
  .subtotal,
  .tax,
  .continue-shopping {
    font-family: $font_1;
  }
  .payment-book__item,
  .address-book__item,
  .payment-book__controls-link {
    font-family: $font_1;
  }
  .payment-info-small {
    .payment-info-small__card-info-label {
      font-family: $font_1;
    }
    .payment-info-small__address-header {
      font-family: $font_1;
    }
  }
  .payment-form .label,
  .payment-form__address-fieldset .choose-address label {
    font-family: $font_1;
  }
  .payment-display {
    h3 {
      font-family: $font_1;
    }
  }
  .giftwrap.label,
  .giftwrap.value,
  .gift-card__link {
    font-family: $font_1;
  }
  .giftcard-page {
    .giftcard-label {
      font-family: $font_1;
    }
    .giftcard-copy {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
  }
  .gift-card-display {
    font-family: $font_1;
  }
  .review-submit__header {
    font-family: $font_1;
  }
  .guarantee-panel__title {
    font-family: $font_1;
  }
  .checkout__sidebar {
    .guarantee-panel__title,
    .links-panel__title {
      font-family: $font_1;
    }
  }
  .sidebar-menu {
    .sidebar-menu__link {
      font-family: $font_1;
    }
  }
  .sidebar-chat {
    .sidebar-chat__link {
      font-family: $font_1;
    }
  }
  /* SPP , MPP */
  .spp-product__details .spp-product__details-header {
    font-family: $font_2;
  }
  .mpp-product-compare-nav {
    li.mpp-compare-button {
      .compare-counter-wrapper .compare-counter {
        font-family: $font_1;
      }
    }
  }
  .mpp-product-compare-tooltip {
    .mpp-compare-now-button {
      font-family: $font_1;
    }
  }
  .spp-product__details .spp-product__details-attribute__label,
  .product_brief__price,
  .product_brief__label2 {
    font-family: $font_1;
  }
  .text-product-anchor,
  .spp-product__anchor,
  .spp-product,
  .product-full {
    font-family: $font_1;
  }
  .text-benefits-line-main,
  .text-benefits-line-sub {
    font-family: $font_1;
  }
  .product-thumb .product-thumb__price {
    font-family: $font_1;
  }
  .spp-product__attribute h5,
  .product-full__attribute h5 {
    font-family: $font_1;
  }
  .spp-product__review,
  .spp-product__attribute p,
  .product-full__review,
  .product-full__attribute p {
    font-family: $font_1;
  }
  .text-prices,
  .text-size,
  .text-promo,
  .text-links {
    font-family: $font_1;
  }
  .wishlist-confirm__button-close,
  .spp-product__detail-link,
  .spp-product__share,
  .spp-product__wishlist,
  .product-full__detail-link,
  .product-full__share,
  .product-full__wishlist {
    font-family: $font_1;
  }
  .text-batch-2-links {
    font-family: $font_1;
  }
  .product-hero-tout .tout__product-copy .cta-button-wrapper .button--plus-sign {
    font-family: $font_1;
  }
  .hero-block__boutique-link--label {
    font-family: $font_1;
  }
  .product-full__misc-flag,
  .product_brief__misc-flag {
    font-family: $font_1;
  }
  .ee-how-to__step__copy a {
    font-family: $font_1;
  }
  .pp__headline--sub-section,
  .pp__headline--sub-section-sub {
    font-family: $font_1;
  }
  .free_standard_shipping_returns {
    font-family: $font_1;
  }
  .terms-heading {
    font-family: $font_1;
  }
  #colorbox.colorbox__quickshop {
    .quickshop {
      font-family: $font_1;
    }
    .quickshop__view-full {
      font-family: $font_1;
    }
    .quickshop__sub-header {
      font-family: $font_1;
    }
    .quickshop__attribute {
      h5 {
        font-family: $font_1;
      }
      p {
        font-family: $font_1;
      }
    }
    .quickshop__add-button {
      font-family: $font_1;
    }
    .quickshop__detail-link {
      font-family: $font_1;
    }
    .quickshop__share {
      font-family: $font_1;
    }
    .quickshop__wishlist {
      font-family: $font_1;
    }
    .quickshop__tabs-control .quickshop__tab-control {
      font-family: $font_1;
    }
  }
  .sd-product-grid {
    .elc-grid-container {
      .elc-product-grid-header-wrapper {
        .elc-product-grid-header {
          font-family: $font_4;
        }
      }
    }
  }
  /* Search */
  .el-search-block {
    .el-search-block__links h5 {
      font-family: $font_1;
    }
  }
  .search-product__misc-flag {
    font-family: $font_1;
  }
  .typeahead-summary p,
  .typeahead-see-results a {
    font-family: $font_1;
  }
  /* Account */
  #forgot_password,
  .password-sent-page .password-sent-page__content .password-sent-page__email-header {
    font-family: $font_1;
  }
  .sign-in-component {
    .sign-in-component__label,
    .sign-in-component__birthday-program label,
    .sign-in-component__sms-signup label {
      font-family: $font_1;
    }
    .sign-in-component__form--registration .sign-in-component__terms,
    .sign-in-component__form--registration .sign-in-component__terms p {
      font-family: $font_1;
    }
  }
  .account-data__controls-link,
  .delete-data-confirm__controls-link,
  .address-book__controls-link {
    font-family: $font_1;
  }
  .account-data-form__label {
    font-family: $font_1;
  }
  .last-purchased__header {
    font-family: $font_1;
  }
  .account-utilities {
    .account-utilities__header {
      font-family: $font_1;
    }
    .account-utilities__link {
      font-family: $font_1;
    }
    .account-utilities__link_livechat {
      font-family: $font_1;
    }
    .account-utilities__account-nav {
      .account-utilities__link.active {
        font-family: $font_1;
      }
    }
  }
  .registration-page {
    .label {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
    .sms-promotions__copy {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
  }
  .delete-address-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_1;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_1;
    }
  }
  .delete-payment-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_1;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_1;
    }
  }
  .address-form .address-form__form-label {
    font-family: $font_1;
  }
  #address_form_container .ship_type_container label {
    font-family: $font_1;
  }
  /* Samples */
  .choose-samples-and,
  .remove-sample {
    font-family: $font_1;
  }
  .samples-panel {
    .skus {
      label {
        font-family: $font_1;
      }
    }
  }
  /* Customer Service */
  .customer-service .sidebar-page__content {
    ul {
      font: 15px/24px $font_1;
    }
    ol {
      font: 15px/24px $font_1;
    }
    p {
      font-family: $font_1;
    }
  }
  .cs-quick-info {
    .cs-quick-info__title {
      font-family: $font_1;
    }
  }
  .careers-page {
    .careers-page__landing-link {
      font-family: $font_1;
    }
    .careers-landing {
      .career-landing__profile-link {
        font-family: $font_1;
      }
    }
  }
  .need-help-panel {
    .phone,
    .chat,
    .email {
      h4 {
        font-family: $font_1;
      }
    }
  }
  /* Beauty Profile */
  .beauty-profile__tools-header,
  .beauty-profile__tools-link {
    font-family: $font_1;
  }
  .beauty-questions__label {
    font-family: $font_1;
  }
  .beauty-profile__questions-submit {
    font-family: $font_1;
  }
  .beauty-chat-tray {
    .beauty-chat-tray .beauty-chat-tray__learn-more {
      font-family: $font_1;
    }
    .beauty-chat-tray__hours-of-availability,
    .beauty-chat-tray__hours-of-availability p {
      font-family: $font_1;
    }
  }
  .beauty-feed-tout {
    .social-user {
      font-family: $font_1;
    }
    .social-action {
      font-family: $font_1;
    }
  }
  /* My feed */
  .my-feed-tray {
    .my-feed-tray__video-header,
    .my-feed-tray__video-title {
      font-family: $font_1;
    }
  }
  /* Special offers */
  .special-offers-tray {
    .offers-email-signup-form .headline--offers-tray {
      font-family: $font_2;
    }
    .offers-email-signup-form .offers-email-signup-form__sign-in-wrapper {
      font-family: $font_1;
      a {
        font-family: $font_1;
      }
    }
    .offers-email-signup-form .offers-email-signup-form__message {
      font-family: $font_1;
    }
    .gift-wrap .gift-wrap__link-wrapper a,
    .gift-wrap .special-offer-benefits__link-wrapper a,
    .special-offer-benefits .gift-wrap__link-wrapper a,
    .special-offer-benefits .special-offer-benefits__link-wrapper a {
      font-family: $font_1;
    }
  }
  /* Botique */
  .boutique-tout {
    .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
      font-family: $font_1;
    }
    .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
      font-family: $font_1;
    }
    &.boutique-tout-size-small {
      .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
        font-family: $font_1;
      }
      .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
        font-family: $font_1;
      }
    }
  }
  .boutique-tout-button {
    font-family: $font_1;
  }
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      font-family: $font_1;
    }
  }
  .ff-quiz__inner {
    .ff-quiz__headline--tertiary,
    .ff-quiz__nav-menu {
      font-family: $font_1;
    }
  }
  .ff-quiz--edit {
    .ff-quiz__inner {
      .ff-quiz__headline--secondary {
        font-family: $font_1;
      }
    }
  }
  .ff-results {
    .ff-results__regimen-step-headline {
      font-family: $font_1;
    }
    .ff-results__matches-copy,
    .ff-results__matches-copy a,
    .ff-results__matches-copy p {
      font-family: $font_1;
    }
    .ff-results__regimen-step-instructions,
    .ff-results__regimen-step-instructions a {
      font-family: $font_1;
    }
  }
  .ff-results__regimen-step-product .product_brief__price {
    font-family: $font_1;
  }
  /* Responsive carousel */
  .responsive-carousel {
    .slide__link {
      font-family: $font_1;
    }
  }
  .responsive-carousel__title {
    font-family: $font_1;
  }
  .r3__container {
    .r3__states .r3__sub-header {
      font-family: $font_1;
    }
  }
  .homepage-subscribe .homepage-subscribe__header {
    font-family: $font_1;
  }
  .sms-signup__terms label {
    font-family: $font_1;
  }
  .country-chooser__selector {
    font-family: $font_2;
  }
  .wishlist-confirm__message {
    font-family: $font_2;
  }
  .estee-edit__header-tags-label {
    font-family: $font_1;
  }
  .formatter-estee-edit__content {
    .basic-textarea-v1 {
      p {
        font: 18px/26px $font_1;
      }
    }
  }
  div.livechat-transcripts-page dl.transcripts {
    font-family: $font_1;
    dd.transcript div {
      &.transcript-lines h3.transcript-section-header {
        font-family: $font_1;
      }
      &.recommended-skus h3.transcript-section-header {
        font-family: $font_1;
      }
    }
  }
  /* Footer */
  .page-footer {
    font-family: $font_1;
    .footer-header,
    .country_chooser__header,
    .sms-signup__header,
    .email_signup__header {
      font-family: $font_2;
    }
    .language-select {
      .language-select__header {
        font-family: $font_1;
      }
    }
  }
  .page-sticky-footer {
    .drawer-formatter__content .special-offer {
      .special-offer__header {
        font-family: $font_1;
      }
    }
    .page-sticky-footer__left .node-elc-nodeblock .sticky-footer__my-feed-link .item-count {
      font-family: $font_1;
    }
    .page-sticky-footer__right .menu > li .sticky-footer__my-feed-link .item-count {
      font-family: $font_1;
    }
  }
  .footer-phone .footer-phone__link {
    font-family: $font_1;
  }
  /* Store locator */
  &.section-store-locator.device-pc {
    .store-locator {
      .location-info {
        font-family: $font_1;
      }
      .intl-search {
        .intl-search__copy {
          font-family: $font_1;
        }
      }
      .door-row {
        .door-row__closest {
          font-family: $font_1;
        }
      }
      .local-search__option {
        font-family: $font_1;
        &.active {
          font-family: $font_1;
        }
      }
      .directions-form__results {
        font-family: $font_1;
      }
      .store-locator__nav .store-locator__nav-item {
        font-family: $font_1;
        &.active {
          font-family: $font_1;
        }
      }
      .doors-results__pager .doors-results__pager-view-all {
        font-family: $font_1;
      }
    }
  }
  .store-locator-block {
    .store-locator-block__results {
      .store-locator-block__results-header {
        font-family: $font_1;
      }
      .store-locator-block__link a {
        font-family: $font_1;
      }
    }
    .store-locator-block__errors {
      font-family: $font_1;
    }
    .store-locator-block__header {
      font-family: $font_1;
    }
    .store-locator-block__loading-message {
      font-family: $font_1;
    }
  }
  &.brand-aerin {
    font-family: $font_1;
  }
  &.brand-renutriv {
    font-family: $font_1;
    h3.product-full__subline {
      font-family: $font_2;
    }
    .spp-product__details {
      .spp-product__details-attribute__label {
        font-family: $font_1;
      }
    }
  }
  /* BV Implementation */
  .BV,
  .BVSubmissionTimeout {
    font-family: $font_1;
  }
  button.BVButton,
  .BVFieldPreview .BVButton,
  .BVFieldSubmit .BVButton {
    font-family: $font_1;
  }
  .BVSelectField div.selector select {
    font-family: $font_1;
  }
  .uploader {
    font-family: $font_1;
    input[type='file'] {
      font-family: $font_1;
    }
  }
  .BVRRRootElement {
    font-family: $font_1;
    input,
    select,
    textarea {
      font-family: $font_1;
    }
  }
  .BVRRContainer {
    div.selector {
      select {
        font-family: $font_1;
      }
    }
  }
  .BVDI_FV {
    .BVDI_FVVote {
      a {
        font-family: $font_1;
        &:hover,
        &.focus,
        &:active {
          font-family: $font_1;
        }
      }
    }
  }
  .BVRRPhotoPopup,
  .BVRRVideoPopup {
    font-family: $font_1;
  }
  /* Japanese Letters */
  .BVRRTitle,
  .ee-landing__tile .ee-landing__text h4,
  .ee--subhead,
  .headline--quinary,
  .headline--page,
  .headline--primary,
  .headline--tertiary,
  .h1.header-meta__headline,
  .header-meta__headline,
  .header-meta__subhead,
  .product_brief__header.product_brief__sub-line,
  .product-full__subline,
  .quickshop__sub-line,
  .samples .sub-line,
  .spp-product__details-description,
  .product_subline.cart-item__product-name,
  .mincho,
  .text-long-body-copy,
  .wrapper_jp .mincho {
    font-family: $font_2;
  }
  .BVDIBody,
  .BVRRPagerColumn a:link,
  .gothic,
  .or,
  .wrapper_jp .gothic {
    font-family: $font_1;
  }
  .email_popover {
    .email_popover__disclaimer {
      font-family: $font_1;
      p,
      a {
        font-family: $font_1;
      }
    }
  }
  .mpp__header,
  .el-search-block input[type='text'].form-text {
    font-family: $font_4;
  }
  #colorbox.colorbox__quickshop {
    .quickshop h3,
    .quickshop__sub-header {
      font-family: $font_4;
    }
  }
  .ff-results__regimen-step-product .product_brief__header,
  .ff-results__regimen-step-product .product_brief__sub-header,
  .ff-results__product .sku-brief-editorial__shadename {
    font-family: $font_3;
  }
  .product_brief__header,
  .product_brief__sub-header {
    font-family: $font_3;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    font-family: $font_3;
  }
}

.recommended-products-panel {
  .recommended-product-items {
    .cart_cross_sell_item {
      .item_info {
        .name {
          font-family: $font_3;
        }
        .subhead {
          font-family: $font_2;
        }
      }
    }
  }
}
/* new changes from JP team */
.mincho,
.checkout__panel-title,
.spp-product__mini-bag-sub-header,
.BVDI_QT .BVRRRatingOverall .BVRRRatingNumberPrefix {
  font-family: $font_2;
}

.brand-renutriv {
  .spp-product__details .spp-product__details-header,
  .discover_more__product-sub-header,
  .page-main h5 {
    font-family: $font_2;
  }
  .product-full__attribute p,
  .page-main p {
    font-family: $font_1;
  }
  .spp-product__details {
    .spp-product__details-description p,
    .spp-product__details-attribute p,
    .spp-product__details-attribute__label {
      font-family: $font_1;
    }
  }
  .product-full__detail-link,
  a.selectBox,
  .product-full,
  .page-main {
    font-family: $font_1;
  }
  .product-brief__button-quickshop,
  .product-brief__button-quickshop--close {
    font-family: $font_1;
  }
}

.checkout__sidebar h2 {
  font-family: $font_2;
}

.section-store-locator.device-pc .store-locator {
  .door-row .door-row__name,
  .store-results-header {
    font-family: $font_2;
  }
  .doors-results__pager .doors-results__pager-view-all {
    font-family: $font_1;
  }
}

.checkout .viewcart-header .messages {
  font-family: $font_1;
}

#colorbox.colorbox__quickshop .quickshop__tabs-control .quickshop__tab-control.quickshop__tab-control--2:first-child,
.formatter-estee-edit .header-meta__subhead,
.brand-renutriv .page-main .product-brief__sub-line,
.node-9736 h4,
.product-brief__sub-line,
.checkout__sidebar h2,
.page-navigation .level-1,
.spp-product__additional-section-button,
.spp-product__reviews-header--mobile,
.text-product-names-sub,
.spp-howtouse__header,
.spp-product__additional-section .spp-howtouse .spp-howtouse__section1 .spp-howtouse__header.headline--secondary span,
.BVRRRatingNumberPrefix,
.BVRRTitle,
.section-store-locator.device-mobile .store-locator .store-locator__title,
.samples-page .samples-panel__title,
.checkout-page-title,
.registration-page__header .headline--page,
.headline--section,
.settings-info__header,
.ee-mb-tile__text h4 {
  font-family: $font_2;
}

.gothic,
.page-footer,
body .country-chooser__selector,
.samples-page .available,
.search-product__sub-header,
p.discover-more_subHeader {
  font-family: $font_1;
}

.hero-block__promo-copy {
  .text-promo__subhead,
  .text-promo__subcopy {
    font-family: $font_1;
  }
}

body blockquote,
span.edith1sub__jp,
p,
.text--short,
label,
.link--bold,
.promo-unit__link,
.product-brief__button-quickshop--close {
  font-family: $font_1;
}

.quickshop-inline__wishlist {
  &:link,
  &:visited {
    font-family: $font_1;
  }
}

.quickshop-inline__details {
  &:link,
  &:visited {
    font-family: $font_1;
  }
}

.text-promo,
.button--light,
.btn,
select {
  font-family: $font_1;
}

a {
  &.selectBox,
  &.selectbox {
    font-family: $font_1;
  }
}

.spp-product__details h5,
.BVRRBuyAgainContainer *,
.page-footer {
  font-family: $font_1;
}

.account-mobile-landing-page .account-landing-menu .account-landing-menu__link,
.search-product__sub-header,
.quickshop-inline__attribute-header,
.text-benefits-line-sub,
.spp-product__review,
.spp-product__attribute p {
  font-family: $font_1;
}

.optima,
.spp-product__sub-header {
  font-family: $font_4;
}

.BVDI_QT .BVRRRatingOverall {
  .BVRRRatingNormalOutOf,
  .BVRRRatingRangeNumber {
    font-family: $font_4;
  }
}

.node-elc-mpp h1.header__headline.headline--large span,
span.eng_jp_font,
.product-full__title {
  font-family: $font_4;
}

h1 {
  &.header-meta__headline,
  &.headline--primary {
    font-family: $font_4;
  }
}

.page-navigation .depth-1 h3,
.quickshop-inline__attribute-text,
.spp-product__sub-line,
.ee-article-products__header h2 {
  font-family: $font_4;
}
